import React from 'react'
import '../../styles/Homepage/Section4.css'
import { ServiceAccordion } from './ServiceAccordion'

export const Section4 = () => {
    return (
        <div className='section-4-container' id='section-4-container'>
            <div>
                <div className='section-4-heading'>
                    <p>Services</p>
                    <p className='bold-heading'>Why Unitres Tech is Better?</p>
                </div>
                <p>We provide All-in-one Solution for every IT job</p>
            </div>
            <ServiceAccordion />
        </div>
    )
}
