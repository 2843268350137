import React, { useState } from 'react';
import "../../styles/CircleInfo/CircleApplicationModernization.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Carousel from '../../components/Carousel.jsx';
import { ApplicationModernization as slides } from '../../data/ApplicationModernization.js';

export const CircleApplicationModernization = () => {

    const [selectedItem, setSelectedItem] = useState(0);
    const [valuedata, setvaluedata] = useState(1);
    const [colordata, setcolordata] = useState({
        1: true,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false
    });


    const handleChange = (index) => {
        setSelectedItem(index);
        setvaluedata(index + 1);
        setcolordata({
            1: index === 0,
            2: index === 1,
            3: index === 2,
            4: index === 3,
            5: index === 4,
            6: index === 5,
            7: index === 6,
            8: index === 7,
            9: index === 8,
            10: index === 9,
            11: index === 10,
            12: index === 11,
        });
    }

    const handleNext = () => {
        setSelectedItem((prev) => (prev + 1) % slides.length);
        let nextIndex = (selectedItem + 1) % slides.length;
        setvaluedata(nextIndex + 1);
        setcolordata({
            1: nextIndex === 0,
            2: nextIndex === 1,
            3: nextIndex === 2,
            4: nextIndex === 3,
            5: nextIndex === 4,
            6: nextIndex === 5,
            7: nextIndex === 6,
            8: nextIndex === 7,
            9: nextIndex === 8,
            10: nextIndex === 9,
            11: nextIndex === 10,
            12: nextIndex === 11,
        });
    };

    const handlePrev = () => {
        setSelectedItem((prev) => (prev - 1 + slides.length) % slides.length);
        let prevIndex = (selectedItem - 1 + slides.length) % slides.length;
        setvaluedata(prevIndex + 1);
        setcolordata({
            1: prevIndex === 0,
            2: prevIndex === 1,
            3: prevIndex === 2,
            4: prevIndex === 3,
            5: prevIndex === 4,
            6: prevIndex === 5,
            7: prevIndex === 6,
            8: prevIndex === 7,
            9: prevIndex === 8,
            10: prevIndex === 9,
            11: prevIndex === 10,
            12: prevIndex === 11,
        });
    };

    return (
        <div>
            <div className='CMApp-modern-Circle'>
                <div className="CMmainDiagram">
                    <div onClick={() => handleChange(0)} className='CMCircleiteam'>
                        <p style={{ color: (colordata[1]) ? "White" : "black", background: (colordata[1]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                            Assessment and Planning
                        </p>
                    </div>
                    <div className="CMdiagram-component" style={{ gap: "500px" }}>
                        <div onClick={() => handleChange(11)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[12]) ? "White" : "black", background: (colordata[12]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Post-Implementation Review
                            </p>
                        </div>
                        <div onClick={() => handleChange(1)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[2]) ? "White" : "black", background: (colordata[2]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Define Goal & Metrics
                            </p>
                        </div>
                    </div>
                    <div className="CMdiagram-component" style={{ gap: "650px" }}>
                        <div onClick={() => handleChange(10)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[11]) ? "White" : "black", background: (colordata[11]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Deployment and Monitoring
                            </p>
                        </div>
                        <div onClick={() => handleChange(2)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[3]) ? "White" : "black", background: (colordata[3]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Select Modernization Approaches
                            </p>
                        </div>
                    </div>
                    <div className="CMdiagram-component" style={{ gap: "680px" }}>
                        <div onClick={() => handleChange(9)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[10]) ? "White" : "black", background: (colordata[10]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Documentation
                            </p>
                        </div>
                        <div onClick={() => handleChange(3)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[4]) ? "White" : "black", background: (colordata[4]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Technology Stack Selection
                            </p>
                        </div>
                    </div>
                    <div className="CMdiagram-component" style={{ gap: "610px" }}>
                        <div onClick={() => handleChange(8)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[9]) ? "White" : "black", background: (colordata[9]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Security and Compliance
                            </p>
                        </div>
                        <div onClick={() => handleChange(4)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[5]) ? "White" : "black", background: (colordata[5]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Data Migration
                            </p>
                        </div>
                    </div>
                    <div className="CMdiagram-component" style={{ gap: "400px" }}>
                        <div onClick={() => handleChange(7)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[8]) ? "White" : "black", background: (colordata[8]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Continuous Improvement
                            </p>
                        </div>
                        <div onClick={() => handleChange(5)} className='CMCircleiteam'>
                            <p style={{ color: (colordata[6]) ? "White" : "black", background: (colordata[6]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                                Testing
                            </p>
                        </div>
                    </div>
                    <div onClick={() => handleChange(6)} className='CMCircleiteam'>
                        <p style={{ color: (colordata[7]) ? "White" : "black", background: (colordata[7]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>
                            Training and Change Management
                        </p>
                    </div>
                    <div className='CMcircle_dig'>
                        {selectedItem !== null ? (
                            <div>
                                <h1>{slides[selectedItem].title}</h1>
                                {slides[selectedItem].content.map((elem, idx) => (
                                    <ul key={idx}>
                                        <li>{elem}</li>
                                    </ul>
                                ))}
                                <div className="CMnavigation-buttons">
                                    <button onClick={handlePrev}><FontAwesomeIcon icon={faArrowRight} rotation={180} /></button>
                                    <p>{valuedata}</p>
                                    <button onClick={handleNext}><FontAwesomeIcon icon={faArrowRight} /></button>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <h1>Secure</h1>
                                <ul>
                                    <li>Inventory and Analysis: Take stock of existing applications, their architecture, dependencies, and performance.</li>
                                    <li>Business Alignment: Understand current and future business goals to ensure modernization efforts align with organizational objectives.</li>
                                    <li>Risk Analysis: Identify potential risks and challenges associated with the modernization process.</li>
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='CMCarousel'>
                <Carousel slides={slides} />
            </div>
        </div>
    );
}

export default CircleApplicationModernization;
