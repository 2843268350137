import { Box, Typography, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import send from '../../assets/Common/Send.png'

export const Section3 = () => {
    const navigate = useNavigate()

    const handleFeature = () => {
        navigate('/api-gateway/feature')
    }

    return (
        <Box sx={{
            height: {
                xs: 'auto',
                sm: 'auto',
                md: '500px',
                lg: '500px',
                xl: '500px'
            },
            p: {
                xs: 2,
                sm: 2,
                md: 0,
                lg: 0,
                xl: 0
            },
            display: 'flex',
            position: 'absolute',
            top: {
                xs: '60%',
                sm: '50%',
                md: '50%',
                lg: '50%',
                xl: '50%',
            },

            width: '100%',
            zIndex: 55
        }}>
            <Box sx={{
                width:
                {
                    xs: '80%',
                    sm: '80%',
                    md: '100%',
                    lg: '100%',
                    xl: '100%',
                },
                display: 'flex',
                gap: '2rem',
                flexDirection: 'column',
                justifyContent: 'center',
                textAlign: 'center',
                m: 'auto'
            }}>
                <Typography sx={{
                    fontWeight: 'bold',
                    fontSize: {
                        xs: '36px',
                        sm: '36px',
                        md: '48px',
                        lg: '48px',
                        xl: '48px',
                    },
                }}>Stay Ahead Of The Curve</Typography>
                <Typography sx={{ fontSize: '18px' }}>Don't miss out on the future of API management. Check out the Features</Typography>
                <Box>

                    <Button
                        onClick={handleFeature}
                        endIcon={<img src={send} alt="Send" />}
                        sx={{
                            backgroundColor: '#FFFFFF', color: '#2D2D2D', fontSize: '20px', borderRadius: '25px', textTransform: 'none', p: '10px 20px',
                            '&:hover': {
                                backgroundColor: '#FFFFFF',
                                color: '#2D2D2D',
                            },
                        }}
                    >
                        Check out Features
                    </Button>
                </Box>
            </Box>
        </Box >

    )
}