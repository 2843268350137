import React from 'react'
import { Box, Typography } from '@mui/material'
import section2 from '../../assets/Homepage/section-2.png'
import section2mob from '../../assets/Homepage/section-2-mob.png'
import section21 from '../../assets/Homepage/section-2-1.png'
import { Section3 } from './Section3'

export const Section2 = () => {
    return (
        <Box>
            <Box sx={{
                width: {
                    xs: '90%',
                    sm: '90%',
                    md: '80%',
                    lg: '80%',
                    xl: '80%',
                },
                m: 'auto',
                mt: '1rem',
                textAlign: 'center'
            }}>
                <Typography sx={{
                    width: {
                        md: '40%',
                        lg: '40%',
                        xl: '40%',
                    },
                    m: 'auto',
                    mb: '1rem',
                    fontSize: {
                        xs: '22px',
                        sm: '22px',
                        md: '36px',
                        lg: '36px',
                        xl: '36px',
                    },
                    fontWeight: 'bold'
                }}>APIs: The invisible powerhouse behind successful applications</Typography>
                <Typography sx={{
                    width: {
                        xs: '90%',
                        sm: '90%',
                        md: '50%',
                        lg: '50%',
                        xl: '50%',
                    },
                    m: 'auto',
                    mb: {
                        xs: '2rem',
                        sm: '2rem',
                        md: '10rem',
                        lg: '10rem',
                        xl: '10rem',
                    },
                    color: 'rgba(255, 255, 255, 0.6)',
                    fontSize: {
                        xs: '18px',
                        sm: '18px',
                        md: '18px',
                        lg: '18px',
                        xl: '18px',
                    }
                }}>In the digital world, APIs (Application Programming Interfaces) act as middlemen between different software applications. They allow applications to talk to each other securely and efficiently, exchanging data and functionality.</Typography>
            </Box>

            <Box sx={{ position: 'relative', width: '100%', mb: 0, backgroundColor: 'black' }}>
                <Box component='img' src={section2} sx={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    backgroundColor: '#000B1C',
                    display: {
                        xs: 'none',
                        sm: 'none',
                        md: 'block',
                        lg: 'block',
                        xl: 'block'
                    }
                }} />
                <Section3 />
                <Box sx={{
                    position: {
                        xs: 'relative',
                        sm: 'relative',
                        md: 'absolute',
                        lg: 'absolute',
                        xl: 'absolute',
                    },
                    top: {
                        md: 300,
                        lg: 300,
                        xl: 300,
                    },
                    left: {
                        md: '25%',
                        lg: '25%',
                        xl: '25%',
                    },
                    width: {
                        xs: '90%',
                        sm: '90%',
                        md: '50%',
                        lg: '50%',
                        xl: '50%',
                    },
                    m: {
                        xs: 'auto',
                        sm: 'auto'
                    }
                }}>
                    <Box sx={{
                        display: {
                            xs: 'block',
                            sm: 'block',
                            md: 'none',
                            lg: 'none',
                            xl: 'none',
                        },
                        textAlign: 'center'
                    }}>
                        <Box component='img' src={section21} sx={{ width: '50%' }} />
                    </Box>

                    <Typography sx={{
                        fontWeight: 100,
                        textAlign: 'center',
                        fontSize: {
                            xs: '34px',
                            sm: '34px',
                            md: '48px',
                            lg: '48px',
                            xl: '48px',
                        }
                    }}>APIs are the backbone of modern applications.
                        <Typography component="span"
                            sx={{
                                fontWeight: '900',
                                fontSize: {
                                    xs: '34px',
                                    sm: '34px',
                                    md: '48px',
                                    lg: '48px',
                                    xl: '48px',
                                }
                            }}> But are you capturing their full potential?
                        </Typography>
                    </Typography>
                    <Box sx={{
                        display: {
                            xs: 'block',
                            sm: 'block',
                            md: 'none',
                            lg: 'none',
                            xl: 'none',
                        }
                    }}>
                        <Box component='img' src={section2mob} sx={{
                            width: '100%'
                        }} />
                    </Box>
                </Box>
            </Box>
        </Box >
    )
}
