import React,{useEffect, useState} from 'react';
import '../../styles/Career/Career2.css'
// import data from "../../data/Career.json"
import axios from 'axios';

export const Career2 = () => {
  const [openDetails, setOpenDetails] = useState({});

  const [alldata, setalldata] = useState([]);

  const url = process.env.REACT_APP_BASE_URL;


    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${url}/api/career`);
            // console.log(response.data);
            setalldata(response.data);
          }
          catch (error) {
            console.log(error);
          }
        };
    
        fetchData();
      }, [url]);


  const toggleDetails = (jobNumber) => {
    setOpenDetails(prevState => ({
      ...prevState,
      [jobNumber]: !prevState[jobNumber]
    })); 
  };
  useEffect(()=>{

  },[openDetails])


  return (
    <div>
      <div className='careerContainerOne'>
        <div>
          <h2 style={{textAlign:"center",fontWeight: "700",fontSize:"calc(1.35rem + 1.2vw)"}} id='Job-Opening'>Job Opening</h2>
        </div>
        {alldata?.map((elem,idx)=>{
          
            const isOpen = openDetails[elem.id];
            return elem.is_open && <div key={elem.id}>
                <h2 style={{fontWeight:"500",fontSize:"1rem",color: isOpen ? "green" : "rgba(0, 0, 0, 0.5)"}} >{elem.name}</h2>
                <details open={elem.openDetails} onClick={() => toggleDetails(elem.id)}>
                <summary className='Careerdeatils'></summary>
                <h6 style={{fontWeight:"700",fontSize:"1rem"}}>Location : {elem.location}</h6>
                <h6 style={{fontWeight:"700",fontSize:"1rem"}}>Job positions: {elem.job_positions}</h6>
                <h6 style={{fontWeight:"700",fontSize:"1rem"}}>Job Description:</h6>
                <p className='CareerContainertwo'> {elem.job_description}</p>
                <h6 className='Jobresponsibility'>
                  <p> Responsibilities: </p> 
                    {
                        JSON.parse(elem.responsibilities).map((elem,idx)=>{
                            return <ul key={idx}>
                                <li>{elem[idx+1]}</li>
                            </ul>
                        })
                    }
                </h6>
                <h6 className='JobSkill'>
                  <p> Skills Required: </p>
                    {
                          JSON.parse(elem.candidate_requirements).map((elem,idx)=>{
                            // console.log(elem);
                            return <ul key={idx}>
                                <li>{elem[idx+1]}</li>
                            </ul>
                        })
                    }
                </h6>
                {/* <Button ele={CareerBtnProps} /> */}
                <h6 className='JobSkill'>How to Apply:</h6>
                    <p>Submit your resume at <strong>info@unitrestech.com</strong>, portfolio, and cover letter explaining your interest and experience.</p>
              </details>
              <hr />
            </div> 
            
        })}
      </div>
    </div>
  )
}