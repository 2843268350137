import { useMediaQuery } from '@mui/material';
import React from 'react';

export default function Button({ ele }) {
  const { text, icon, image, onClick, backgroundColor, color, border } = ele;

  const isSmallScreen = useMediaQuery('(max-width: 600px)');

  const buttonStyle = {
    backgroundColor: backgroundColor || '#20303F',
    color: color || '#ffffff',
    width: 'max-content',
    height: '60px',
    padding: image ? '3% 2%' : '3% 4%',
    borderRadius: '60px',
    fontSize: '18px',
    fontWeight: 'bold',
    border: border,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',

    ...(isSmallScreen && {
      height: '50px',
      fontSize: '14px',
      gap: '3px',
    }),
  };

  const imageStyle = {
    height: "45px",
    ...(isSmallScreen && {
      height: '35px',
    }),
  }

  const iconStyle = {
    marginLeft: '10px',
  };

  return (
    <button style={{ ...buttonStyle }} onClick={onClick}>
      {image && <img src={image} alt="button icon" style={{ ...imageStyle }} />}
      <span>{text}</span>
      {icon && <span style={iconStyle}>{icon}</span>}
    </button>
  );
}
