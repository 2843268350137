import React from 'react'
import '../../styles/Homepage/Section6.css'
import exp1 from '../../assets/Homepage/exp-finance.png'
import exp2 from '../../assets/Homepage/exp-retail.png'
import exp3 from '../../assets/Homepage/exp-health.png'
import exp4 from '../../assets/Homepage/exp-logistics.png'
import exp5 from '../../assets/Homepage/exp-media.png'
import exp6 from '../../assets/Homepage/exp-travel.png'

export const Section6 = () => {
    return (
        <div className='section-6-container'>
            <div className='expertise-top'>
                <p>Our <span className='bold-heading'> Expertise</span> </p>
                <p>We Provide a Wide Range Of Services That Help Businesses</p>
            </div>

            <div className='expertise-bottom'>
                <img src={exp1} alt="Financial Services" />
                <img src={exp2} alt="Retail" />
                <img src={exp3} alt="Healthcare" />
                <img src={exp4} alt="Logistics & Distribution" />
                <img src={exp5} alt="Media & Entertainment" />
                <img src={exp6} alt="Travel & Hospitality" />
            </div>
        </div>
    )
}
