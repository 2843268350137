import React from 'react'
import '../styles/Services/CloudEngSectionSixContainer.css';
import tbl1Icon from "../assets/Services/CloudEngg/cld-section5-table1-icon.png"
import tbl2Icon from "../assets/Services/CloudEngg/cld-section5-table2-icon.png"
import tbl3Icon from "../assets/Services/CloudEngg/cld-section5-table3-icon.png"

export const CloudEngSectionSix = () => {
  return (
    <div className='CloudEngSectionSixContainer'>
      <div className='CloudEngSectionSixContainer-One'>
        <img src={tbl1Icon} alt="tbl1Icon" />
        <h3>Assess Your Enterprise and Discover the Best Path Forward</h3>
        <hr />
        <ul>
          <li>
            Evaluate client tech, tailor cloud strategy, choose fitting tech and commercial options, and align with transformative goals.
          </li>
        </ul>
      </div>
      <div className='CloudEngSectionSixContainer-Two'>
        <img src={tbl2Icon} alt="tbl2Icon" />
        <h3>Adopt Cloud Computing and Unlock Its Full Potential</h3>
        <hr />
        <ul>
          <li>Accelerate enterprise cloud adoption with swift hosting, re-platforming, and top-tier PaaS integration. </li>
          <li>Our automation-driven approach minimizes effort and accelerates goal achievement.</li>
        </ul>
      </div>
      <div className='CloudEngSectionSixContainer-Three'>
        <img src={tbl3Icon} alt="tbl3Icon" />
        <h3>Modernize and Transform Applications to Cloud-Native Services</h3>
        <hr />
        <ul>
          <li>We modernize legacy systems with cloud-native solutions, including APIs, microservices, containers, and NoSQL databases.</li>
          <li>Our Customized Application Development platform accelerates transformation through data extraction and hyper-automation, reducing reliance on specialized skills.</li>
        </ul>
      </div>
    </div>
  )
}
