import { useState } from 'react'
import "../../styles/CircleInfo/CircleIntelligentAutomation.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from '../../components/Carousel.jsx'
import { IntelligentAutomation as slides } from '../../data/IntelligentAutomation.js'

export const CircleIntelligentAutomation = () => {

    const [selectedItem, setSelectedItem] = useState(0);
    const [valuedata, setvaluedata] = useState(1);
    const [colordata, setcolordata] = useState({
        1: true,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false,
        8: false,
        9: false,
        10: false,
        11: false,
        12: false,
        13: false,
        14: false
    })


    const handleChange = (index) => {
        setSelectedItem(index);
        setvaluedata(index + 1);
        setcolordata({
            1: index === 0,
            2: index === 1,
            3: index === 2,
            4: index === 3,
            5: index === 4,
            6: index === 5,
            7: index === 6,
            8: index === 7,
            9: index === 8,
            10: index === 9,
            11: index === 10,
            12: index === 11,
            13: index === 12,
            14: index === 13
        });
    }

    const handleNext = () => {
        setSelectedItem((prev) => (prev + 1) % slides.length);
        let nextIndex = (selectedItem + 1) % slides.length;
        setvaluedata(nextIndex + 1);
        setcolordata({
            1: nextIndex === 0,
            2: nextIndex === 1,
            3: nextIndex === 2,
            4: nextIndex === 3,
            5: nextIndex === 4,
            6: nextIndex === 5,
            7: nextIndex === 6,
            8: nextIndex === 7,
            9: nextIndex === 8,
            10: nextIndex === 9,
            11: nextIndex === 10,
            12: nextIndex === 11,
            13: nextIndex === 12,
            14: nextIndex === 13
        });
    };

    const handlePrev = () => {
        setSelectedItem((prev) => (prev - 1 + slides.length) % slides.length);
        let prevIndex = (selectedItem - 1 + slides.length) % slides.length;
        setvaluedata(prevIndex + 1);
        setcolordata({
            1: prevIndex === 0,
            2: prevIndex === 1,
            3: prevIndex === 2,
            4: prevIndex === 3,
            5: prevIndex === 4,
            6: prevIndex === 5,
            7: prevIndex === 6,
            8: prevIndex === 7,
            9: prevIndex === 8,
            10: prevIndex === 9,
            11: prevIndex === 10,
            12: prevIndex === 11,
            13: prevIndex === 12,
            14: prevIndex === 13

        });
    };

    return (
        <div>
            <div className='CMApp-modern-Circle'>
                <div className="mainDiagram">
                    <div onClick={() => handleChange(0)} className='Circleiteam'><p style={{ color: (colordata[1]) ? "White" : "black", background: (colordata[1]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Assessment and Goal Setting</p></div>
                    <div className="diagram-component" style={{ gap: "400px" }}>
                        <div onClick={() => handleChange(13)} className='Circleiteam'><p style={{ color: (colordata[14]) ? "White" : "black", background: (colordata[14]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Cost-Benefit Analysis</p></div>
                        <div onClick={() => handleChange(1)} className='Circleiteam'><p style={{ color: (colordata[2]) ? "White" : "black", background: (colordata[2]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Process Identification</p></div>
                    </div>
                    <div className="diagram-component" style={{ gap: "580px" }}>
                        <div onClick={() => handleChange(12)} className='Circleiteam'><p style={{ color: (colordata[13]) ? "White" : "black", background: (colordata[13]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Documentation and Knowledge Transfer</p></div>
                        <div onClick={() => handleChange(2)} className='Circleiteam'><p style={{ color: (colordata[3]) ? "White" : "black", background: (colordata[3]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Technology Selection</p></div>
                    </div>

                    <div className="diagram-component" style={{ gap: "650px" }}>
                        <div onClick={() => handleChange(11)} className='Circleiteam'><p style={{ color: (colordata[12]) ? "White" : "black", background: (colordata[12]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Feedback Loop</p></div>
                        <div onClick={() => handleChange(3)} className='Circleiteam'><p style={{ color: (colordata[4]) ? "White" : "black", background: (colordata[4]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Data Collection and Preparation</p></div>
                    </div>

                    <div className="diagram-component" style={{ gap: "700px" }}>
                        <div onClick={() => handleChange(10)} className='Circleiteam'><p style={{ color: (colordata[11]) ? "White" : "black", background: (colordata[11]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Compliance and Security</p></div>
                        <div onClick={() => handleChange(4)} className='Circleiteam'><p style={{ color: (colordata[5]) ? "White" : "black", background: (colordata[5]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Proof of Concept (PoC)</p></div>
                    </div>
                    <div className="diagram-component" style={{ gap: "600px" }}>
                        <div onClick={() => handleChange(9)} className='Circleiteam'><p style={{ color: (colordata[10]) ? "White" : "black", background: (colordata[10]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Scalability and Expansion</p></div>
                        <div onClick={() => handleChange(5)} className='Circleiteam'><p style={{ color: (colordata[6]) ? "White" : "black", background: (colordata[6]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Development and Integration</p></div>
                    </div>

                    <div className="diagram-component" style={{ gap: "450px" }}>
                        <div onClick={() => handleChange(8)} className='Circleiteam'><p style={{ color: (colordata[9]) ? "White" : "black", background: (colordata[9]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Monitoring and Optimization</p></div>
                        <div onClick={() => handleChange(6)} className='Circleiteam'><p style={{ color: (colordata[7]) ? "White" : "black", background: (colordata[7]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Change Management</p></div>
                    </div>
                    <div onClick={() => handleChange(7)} className='Circleiteam'><p style={{ color: (colordata[8]) ? "White" : "black", background: (colordata[8]) && "linear-gradient(90deg, #0096FE 74.98%, #FFFFFF 112.28%)" }}>Deployment</p></div>

                    <div className='circle_dig' >
                        {selectedItem !== null ? (
                            <div>
                                <h1>{slides[selectedItem].title}</h1>
                                {slides[selectedItem].content.map((elem, idx) => (
                                    <ul key={idx}> {/*Prev  */}
                                        <li>{elem}</li>
                                    </ul>
                                ))}
                                <div className="navigation-buttons">
                                    <button onClick={handlePrev}><FontAwesomeIcon icon={faArrowRight} rotation={180} /></button>
                                    <p>{valuedata}</p> {/*Next  */}
                                    <button onClick={handleNext}><FontAwesomeIcon icon={faArrowRight} /></button>
                                </div>
                            </div>
                        ) :
                            <div>
                                <h1>Secure</h1>
                                <ul>
                                    <li>Inventory and Analysis: Take stock of existing applications, their architecture, dependencies, and performance.</li>
                                    <li>Business Alignment: Understand current and future business goals to ensure modernization efforts align with organizational objectives.</li>
                                    <li>Risk Analysis: Identify potential risks and challenges associated with the modernization process.</li>
                                </ul>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className='CMCarousel'>
                <Carousel slides={slides} />
            </div>
        </div>
    );
}

