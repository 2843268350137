import selenium from '../../assets/Techstacks/Quality/selenium.png'
import selenide from '../../assets/Techstacks/Quality/selenide.jpg'
import jira from '../../assets/Techstacks/Quality/jira.png'
import browserstack from '../../assets/Techstacks/Quality/browserstack.png'
import soapui from '../../assets/Techstacks/Quality/soapui.png'
import cucumber from '../../assets/Techstacks/Quality/cucumber.png'
import appium from '../../assets/Techstacks/Quality/appium.png'
import lambda from '../../assets/Techstacks/Quality/lambda.png'
import katalon from '../../assets/Techstacks/Quality/katalon.png'
import playwright from '../../assets/Techstacks/Quality/playwright.png'

export const quality = [
    {
        id: 1,
        name: 'Selenium',
        logo: selenium
    },
    {
        id: 2,
        name: 'Selenide',
        logo: selenide
    },
    {
        id: 3,
        name: 'Jira',
        logo: jira
    },
    {
        id: 4,
        name: 'Browserstack',
        logo: browserstack
    },
    {
        id: 5,
        name: 'SoapUI',
        logo: soapui
    },
    {
        id: 6,
        name: 'Cucumber',
        logo: cucumber
    },
    {
        id: 7,
        name: 'Appium',
        logo: appium
    },
    {
        id: 8,
        name: 'LambdaTest',
        logo: lambda
    },
    {
        id: 9,
        name: 'Katalon Studio',
        logo: katalon
    },
    {
        id: 10,
        name: 'Playwright',
        logo: playwright
    },
]