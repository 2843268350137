import React from 'react'
import { Helmet } from "react-helmet"
import { Box } from '@mui/material'
import { Section1 } from '../components/Homepage/Section1'
import { Section2 } from '../components/Homepage/Section2'
import { Section4 } from '../components/Homepage/Section4'
import { Section5 } from '../components/Homepage/Section5'

export const ApiHomepage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>API Gateway</title>
            </Helmet>

            <Box sx={{
                backgroundColor: '#000B1C',
                color: 'white'
            }}>
                <Section1 />
                <Section2 />
                {/* Section 3 is rendered in Section 2 */}
                <Section4 />
                <Section5 />
            </Box>
        </>
    )
}
