import React from 'react'
import '../../styles/Homepage/Section3.css'
import people1 from '../../assets/Homepage/people-1.png'
import qmark from '../../assets/Homepage/qmark-icon.png'

export const Section3 = () => {
    return (
        <div className='section-3-container'>
            <div className='section-3-top'>
                <div className='section-3-top-line1'>
                    <p>Let's face it,</p>
                    <img src={people1} alt='' />
                    <p>growing your business</p>
                </div>
                <p className='section-3-top-line2'>online is hard.</p>
            </div>

            <div className='section-3-bottom'>
                <div>
                    <img src={qmark} alt="qmark" />
                    <hr />
                    <p>How do I move from Project to Product?</p>
                </div>
                <div>
                    <img src={qmark} alt="qmark" />
                    <hr />
                    <p>How can I get business and IT to align with the flow of value?</p>
                </div>
                <div>
                    <img src={qmark} alt="qmark" />
                    <hr />
                    <p>How do I engage my partners on this journey?</p>
                </div>
                <div>
                    <img src={qmark} alt="qmark" />
                    <hr />
                    <p>How can I exploit the power of data to drive faster value realization?</p>
                </div>
            </div>

            <p className='section-3-end'>See how our services can help you navigate these challenges.</p>
        </div>
    )
}
