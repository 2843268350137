import nodejs from '../../assets/Techstacks/Backend/node-js.png'
import java from '../../assets/Techstacks/Backend/java.png'
import golang from '../../assets/Techstacks/Backend/golang.png'
import python from '../../assets/Techstacks/Backend/python.png'
import csharp from '../../assets/Techstacks/Backend/c-sharp.png'
import ruby from '../../assets/Techstacks/Backend/ruby.png'
import php from '../../assets/Techstacks/Backend/php.png'
import rust from '../../assets/Techstacks/Backend/rust.png'
import kotlin from '../../assets/Techstacks/Backend/kotlin.png'

export const backend = [
    {
        id: 1,
        name: 'Node.js',
        logo: nodejs
    },
    {
        id: 2,
        name: 'Java',
        logo: java
    },
    {
        id: 3,
        name: 'Golang',
        logo: golang
    },
    {
        id: 4,
        name: 'Python',
        logo: python
    },
    {
        id: 5,
        name: 'C#',
        logo: csharp
    },
    {
        id: 6,
        name: 'Ruby',
        logo: ruby
    },
    {
        id: 7,
        name: 'Kotlin',
        logo: kotlin
    },
    {
        id: 8,
        name: 'Rust',
        logo: rust
    },
    {
        id: 9,
        name: 'PHP',
        logo: php
    },
]