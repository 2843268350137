import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from "../assets/Common/logo.png";
import Send from "../assets/Common/Send.png"

export const ApiNavbar2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNotify = () => {
    const notifyElement = document.getElementById('notify');
    notifyElement.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <AppBar position="static" style={{ background: 'rgba(0, 11, 28, 1)', boxShadow: 'none' }}>
      {/* Navbar Section 1 */}
      <Toolbar style={{ justifyContent: 'space-between', width: '90%', margin: 'auto', backgroundColor: "transparent", padding: "10px 5%" }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h6" style={{ marginRight: '50px', display: 'flex', alignItems: 'center', cursor: "pointer" }}>
            <img src={logo} alt="" style={{ width: '150px' }}
              onClick={() => navigate("/")}
            />
          </Typography>
          {!isMobile && (
            <Box style={{
              display: 'flex', alignItems: 'center', gap: '65px',
            }}>
              <Button sx={{
                fontWeight: location.pathname === '/api-gateway' ? 'bold' : '200',
                color: 'White',
                fontSize: "18px",

                lineHeight: "20.16px",
              }} onClick={() => navigate("/api-gateway")} >Home</Button>
              <Button sx={{
                fontWeight: location.pathname === '/api-gateway/feature' ? 'bold' : '200',
                color: 'White',
                fontSize: "18px",
                lineHeight: "20.16px",
              }} onClick={() => navigate("/api-gateway/feature")}>Features</Button>
            </Box>
          )}
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {!isMobile && (
            <Box style={{ display: 'flex', gap: '10px' }}>

              <Button
                onClick={handleNotify}
                sx={{
                  textTransform: 'none',
                  borderRadius: "50px",
                  p: "8px 15px",
                  backgroundColor: 'white',
                  border: "none",
                  color: "black",
                  fontWeight: "400",
                  lineHeight: "24px",
                  fontSize: "18px",
                  '&:hover': {
                    backgroundColor: 'white',
                    color: 'black',
                  }
                }}
                endIcon={<img src={Send} alt="Send" />}
              >
                Notify me
              </Button>
            </Box>
          )}
          {isMobile && (
            <IconButton
              edge="end"
              color="black"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon sx={{ color: "white" }} />
            </IconButton>
          )}
        </Box>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            <ListItem >
              <ListItemText primary="Home" onClick={() => navigate("/api-gateway")} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Features" onClick={() => navigate("/api-gateway/feature")} />
            </ListItem>
            <ListItem>
              <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
                <Button
                  onClick={handleNotify}
                  sx={{
                    textTransform: 'none',
                    borderRadius: "50px",
                    padding: "16px, 40px, 16px, 40px",
                    backgroundColor: 'white',
                    color: "black",
                    fontSize: "17px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    border: "1px solid",
                  }}
                  endIcon={<img src={Send} alt="Send" />}
                >
                  Notify me
                </Button>
              </Box>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};