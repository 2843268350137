export const DataEngineering = [
    {
        id: 1,
        title: "STRATEGIES",
        desc: 'Define goals, requirements, and architecture.',
        content: [
            "Set business objectives and KPIs",
            "Gather technical and functional requirements",
            "Design data architecture",
            "Select tools and technologies"
        ],
        "value": true
    },
    {
        id: 2,
        title: "OPTIMISE",
        desc: 'Enhance efficiency and effectiveness.',
        content: [
            "Improve data quality",
            "Tune performance",
            "Manage resources",
            "Optimize costs"
        ],
        "value": true
    },
    {
        id: 3,
        title: "SECURE",
        desc: 'Protect data integrity and confidentiality.',
        content: [
            "Implement data governance",
            "Control access",
            "Use encryption",
            "Ensure compliance"
        ],
        "value": true
    },
    {
        id: 4,
        title: "DEPLOY",
        desc: 'Implement and manage in production.',
        content: [
            "Set up infrastructure",
            "Deploy data pipelines",
            "Monitor systems",
            "Ensure scalability"
        ],
        "value": true
    },
    {
        id: 5,
        title: "PUBLISH",
        desc: 'Make insights accessible and actionable.',
        content: [
            "Create visualizations",
            "Generate reports",
            "Share data securely",
            "Enable collaboration"
        ],
        "value": true
    },
    {
        id: 6,
        title: "MAINTAIN",
        desc: 'Ensure ongoing reliability and performance.',
        content: [
            "Update systems regularly",
            "Review performance periodically",
            "Manage incidents",
            "Continuously improve processes"
        ],
        "value": true
    },
]