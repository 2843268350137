import React from 'react'
import '../../styles/Homepage/Section9.css'
import logo from '../../assets/logo-2.png'
import Button from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export const Section9 = () => {
    const handleApiGateway = () => {
        window.open('/api-gateway', '_blank');
    };

    const handleUniAlgoTrade = () => {
        window.open('/UniAlgoTrade', '_blank');
    };

    const knowBtn1Props = {
        onClick: handleApiGateway,
        text: 'Know More',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
        border: '2px solid #20303F'
    };

    const knowBtn2Props = {
        onClick: handleUniAlgoTrade,
        text: 'Know More',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
        border: '2px solid #20303F'
    };

    return (
        <div className='section-9'>
            <div className='section-9-top-container'>
                <p >Unitres Tech <span>Upcoming Horizons</span></p>
                <p >We are creating something Amazing!</p>
            </div>
            <div className='section-9-bottom-container'>
                <div className='section-9-bottom-left'>
                    <div className='section-9-bottom-left-content'>
                        <div>
                            <img src={logo} alt='unitres' />
                            <p className='section-9-bottom-head'>Build a solid Foundation For Your Software with our <span style={{ color: '#0096FE', fontWeight: 'bolder' }}>API Gateway</span></p>
                            <p className='section-9-bottom-desc'>Unlock seamless connectivity with our API Gateway : Your digital ecosystem, simplified and secure</p>
                        </div>
                    </div>
                    <div className='section-9-button'>
                        <Button ele={knowBtn1Props} />
                    </div>
                </div>
                <div className='section-9-bottom-right' >
                    <div className='section-9-bottom-right-content'>
                        <img src={logo} alt='unitres' />
                        <p className='section-9-bottom-head'>Accelerate Trading with Advanced Cybersecurity with <span style={{ color: '#33B89F', fontWeight: 'bolder' }}>Trading Portal</span></p>
                        <p>Supercharge Your Trading with Unmatched Security!</p>
                    </div>
                    <div className='section-9-button'>
                        <Button ele={knowBtn2Props} />
                    </div>
                </div>
            </div>
        </div>
    )
}
