import React from 'react'

export default function NotFoundPage() {
  return (
    <div style={{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        textAlign:"center",
        height:"100vh"
    }}>
        <h1>
            404
        </h1>
        <h2>
            Page not Found
        </h2>
    </div>
  )
}
