import React from 'react';
import { Box, Typography } from '@mui/material';
import icon from '../../assets/Homepage/icon.png';

export const Section4 = () => {
    const APIgame = [
        {
            id: 0,
            title: 'Mastering API Management',
            icon: icon,
            description: `We understand the complexities of managing APIs in today's dynamic environment. Our platform empowers you to govern your APIs effectively, from design to deployment.`,
        },
        {
            id: 1,
            title: 'Unlocking the Value Within through API monetization',
            icon: icon,
            description: `We help you turn your APIs into revenue streams with our robust API Monetization features.`,
        },
        {
            id: 2,
            title: 'Performance Under Pressure',
            icon: icon,
            description: `Ensure your APIs are always available and responsive with our advanced Traffic Management solutions.`,
        },
    ];

    const APIpower = [
        {
            id: 0,
            title: 'Security is Paramount',
            icon: icon,
            description: `Protect your valuable data and applications with our comprehensive security features, including access control, encryption, and threat detection.`,
        },
        {
            id: 1,
            title: 'Always on, Always Available',
            icon: icon,
            description: `Downtime is not an option.  Our High Availability architecture guarantees your APIs are accessible 24/7,  no matter what.`,
        },
        {
            id: 2,
            title: 'Segregating for Success',
            icon: icon,
            description: `With Gateway Compartmentalization, you can create isolated environments for different purposes, ensuring optimal performance and security.`,
        },
    ];

    return (

        <Box sx={{
            width: {
                xs: '90%',
                sm: '90%',
                md: '80%',
                lg: '80%',
                xl: '80%',
            },
            m: 'auto',
            p: {
                xs: '5rem 0',
                sm: '5rem 0',
                md: '10rem 0',
                lg: '10rem 0',
                xl: '10rem 0',
            }
        }}>
            {/* First Section */}
            <Box sx={{
                textAlign: 'center',
                mb: {
                    xs: '3rem',
                    sm: '3rem',
                    md: '10rem',
                    lg: '10rem',
                    xl: '10rem',
                }
            }}>
                <Typography sx={{
                    fontSize: {
                        xs: '32px',
                        sm: '32px',
                        md: '48px',
                        lg: '48px',
                        xl: '48px',
                    }
                }}>Introducing The
                    <br />
                    Ultimate API Gateway!</Typography>
            </Box>

            {/* Second Section */}
            <Box sx={{ mb: '3rem ' }}>
                <Box sx={{
                    textAlign: 'center',
                    mb: '32px'
                }}>
                    <Typography sx={{
                        fontSize: '28px',
                    }}>Level Up Your API Game</Typography>
                    <Typography sx={{
                        fontSize: '18px',
                        fontweight: 'lighter',
                        color: 'rgba(255, 255, 255, 0.6)',
                    }}>
                        Focus on Your Core Business, We'll Handle the API Infrastructure.
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1,1fr)',
                        sm: 'repeat(1,1fr)',
                        md: 'repeat(3,1fr)',
                        lg: 'repeat(3,1fr)',
                        xl: 'repeat(3,1fr)',
                    },
                    justifyItems: 'center',
                    p: 2
                }}>
                    {APIgame.map((e) => (
                        <Box key={e.id} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            width: {
                                md: '60%',
                                lg: '60%',
                                xl: '60%',
                            },
                            mb: {
                                xs: '2rem',
                                sm: '2rem',
                            },
                            textAlign: 'center'
                        }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Box component='img' src={e.icon} alt={e.title} style={{ height: '50px', width: '50px' }} />
                            </Box>

                            <hr style={{ width: '100%' }} />
                            <Box>
                                <Typography sx={{
                                    fontSize: '20px',
                                    fontweight: 'bolder',
                                    mb: '1rem'
                                }}><strong>{e.title}</strong></Typography>
                                <Typography sx={{
                                    fontSize: '16px',
                                    color: 'rgba(255, 255, 255, 0.6)',
                                }}>{e.description}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>

            </Box>

            {/* Third Section */}
            <Box >
                <Box sx={{
                    textAlign: 'center',
                    mb: '32px'
                }}>
                    <Typography sx={{
                        fontSize: '28px',
                    }}>With Great power comes great responsibilities</Typography>
                    <Typography sx={{
                        fontSize: '18px',
                        fontweight: 'lighter',
                        color: 'rgba(255, 255, 255, 0.6)',
                    }}>
                        Building a Shield for Your APIs
                    </Typography>
                </Box>

                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: {
                        xs: 'repeat(1,1fr)',
                        sm: 'repeat(1,1fr)',
                        md: 'repeat(3,1fr)',
                        lg: 'repeat(3,1fr)',
                        xl: 'repeat(3,1fr)',
                    },
                    justifyItems: 'center',
                    p: 2
                }}>
                    {APIpower.map((e) => (
                        <Box key={e.id} sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            width: {
                                md: '60%',
                                lg: '60%',
                                xl: '60%',
                            },
                            mb: {
                                xs: '2rem',
                                sm: '2rem',
                            },
                            textAlign: 'center'
                        }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Box component='img' src={e.icon} alt={e.title} style={{ height: '50px', width: '50px' }} />
                            </Box>

                            <hr style={{ width: '100%' }} />
                            <Box>
                                <Typography sx={{
                                    fontSize: '20px',
                                    fontweight: 'bolder',
                                    mb: '1rem'
                                }}><strong>{e.title}</strong></Typography>
                                <Typography sx={{
                                    fontSize: '16px',
                                    color: 'rgba(255, 255, 255, 0.6)',
                                }}>{e.description}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>

            </Box>
        </Box >
    );
};
