// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-7 {
    width: 100%;
    margin-bottom: 10rem;
}

.section-7-desc>div {
    margin: 0;
    font-size: var(--font-size5);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;

    & p {
        margin: 0;
    }
}

.section-7-bold {
    font-weight: bold;
}

@media screen and (min-width:300px) and (max-width:490px) {
    .section-7 {
        margin-bottom: var(--margin-bottom-mobile);
    }

    .section-7-desc>div {
        font-size: 0.85rem;
        gap: 0.1rem;

        & img {
            width: 9%;
        }
    }
}

@media screen and (min-width:601px) and (max-width:840px) {
    .section-7-desc>div {
        font-size: var(--font-size1);
        gap: 0.1rem;

        & img {
            width: 10%;
        }
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Homepage/Section7.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,SAAS;IACT,4BAA4B;IAC5B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;;IAEX;QACI,SAAS;IACb;AACJ;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,0CAA0C;IAC9C;;IAEA;QACI,kBAAkB;QAClB,WAAW;;QAEX;YACI,SAAS;QACb;IACJ;AACJ;;AAEA;IACI;QACI,4BAA4B;QAC5B,WAAW;;QAEX;YACI,UAAU;QACd;IACJ;AACJ","sourcesContent":[".section-7 {\n    width: 100%;\n    margin-bottom: 10rem;\n}\n\n.section-7-desc>div {\n    margin: 0;\n    font-size: var(--font-size5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 0.2rem;\n\n    & p {\n        margin: 0;\n    }\n}\n\n.section-7-bold {\n    font-weight: bold;\n}\n\n@media screen and (min-width:300px) and (max-width:490px) {\n    .section-7 {\n        margin-bottom: var(--margin-bottom-mobile);\n    }\n\n    .section-7-desc>div {\n        font-size: 0.85rem;\n        gap: 0.1rem;\n\n        & img {\n            width: 9%;\n        }\n    }\n}\n\n@media screen and (min-width:601px) and (max-width:840px) {\n    .section-7-desc>div {\n        font-size: var(--font-size1);\n        gap: 0.1rem;\n\n        & img {\n            width: 10%;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
