import React from 'react'
import '../../../styles/Services/common/Section2.css'
import Button from '../../Button'
import trlogo from '../../../assets/Homepage/logo-transparent.png'
import itlogo from '../../../assets/Homepage/it-logo.png'
import quote from '../../../assets/Homepage/quote-icon.png'


export const Section2 = ({ e }) => {
    const { headText, boldHeadText, desc, image } = e

    const handleQuote = () => {
        const footerElement = document.getElementById('sec1-prt2');
        const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 68;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    const quoteBtnProps = {
        onClick: handleQuote,
        text: 'Get a Quote',
        backgroundColor: '#ffffff',
        color: '#20303F',
        image: quote,
        border: '1px solid #20303F'
    };

    return (
        <div className='services-section-2'>
            <img src={image} alt='uitrestech' className='section-2-bg' />
            <div className='services-section-2-content'>
                <img src={trlogo} alt='unitrestech' className='section-2-logo' loading='lazy' />
                <p className='services-section-head'>{headText}<span className='bold-head'>{boldHeadText}</span></p>
                <p className='services-section-desc'>{desc}</p>
                <div className='services-section-2-content-bottom'>
                    <Button ele={quoteBtnProps} />
                    <img src={itlogo} alt='unitrestech' className='itlogo' />
                </div>
            </div>
        </div>
    )
}
