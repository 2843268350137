import React from 'react'
import { Box, useMediaQuery, useTheme } from '@mui/material'
import section5 from '../../assets/Common/discount.png'
import section5mob from '../../assets/Common/discount-mob.png'

export const Section5 = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box sx={{
            height: '600px',
            backgroundImage: `url(${isSmallScreen ? section5mob : section5})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Box sx={{
                fontSize: '36px',
                mt: {
                    xs: '-4rem',
                    sm: '3rem',
                    md: '3rem',
                    lg: '3rem',
                    xl: '3rem',
                }
            }}>& Much More</Box>
        </Box>
    )
}
