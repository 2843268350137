import React from 'react'
import { Helmet } from "react-helmet";
import '../../styles/Services/AppModernization.css'
import Button from '../../components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import brainstorming from '../../assets/Services/AppModernization/team-brainstorming.png'
import section2 from '../../assets/Services/AppModernization/section-2.png'
import section3 from '../../assets/Services/AppModernization/section-3.png'
import icon1 from '../../assets/Services/AppModernization/info-icon.png'
import icon2 from '../../assets/Services/AppModernization/bulb-icon.png'
import icon3 from '../../assets/Services/AppModernization/database-icon.png'
import logo from '../../assets/logo-2.png'
import people1 from '../../assets/Services/AppModernization/people-1.png'
import section_8_1 from '../../assets/Services/AppModernization/section-8-1.jpg'
import section_8_2 from '../../assets/Services/AppModernization/section-8-2.jpg'
import section_8_3 from '../../assets/Services/AppModernization/section-8-3.jpg'
import { Section2 } from '../../components/Services/common/Section2';
import { CircleApplicationModernization } from '../../components/CircleInfo/CircleApplicationModernization'

export const AppModernization = () => {
    const handleContact = () => {
        const footerElement = document.getElementById('sec1-prt2');
        const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 68;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    const contactBtnProps = {
        onClick: handleContact,
        text: 'Get in touch',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
    };

    const section2props = {
        headText: 'Future-Proof Your Business with ',
        boldHeadText: 'Smart Modernization',
        desc: `In today's digital landscape, application modernization is paramount for organizational success. The Unitres team's AI-driven approach offers a transformative path to becoming 'live enterprises'. By seamlessly modernizing legacy applications, businesses can adapt swiftly, ensuring continuity without disruption.`,
        image: section2
    }

    return (
        <div className='application-modernization-container' >
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
                <meta charSet="utf-8" />
                <title>Service - Application Modernization</title>
                <meta name="keywords" content="
                    application modernization,
                    app modernization,
                    legacy modernization,
                    application modernisation,
                    app modernisation,
                    application modernization software,
                    mainframe modernization,
                    legacy applications,
                    application modernization service,
                    infrastructure and application modernization with google cloud,
                    cloud modernization,
                    legacy application modernization,
                    legacy app modernization,
                    aws mainframe modernization,
                    software modernization,
                    legacy system modernization,
                    legacy software modernization,
                    legacy modernization software,
                    legacy system modernisation,
                    ibm application modernization,
                    application modernization roadmap,
                    cloud application modernization,
                    application modernization,
                    application modernization strategy,
                    aws application modernization,
                    ibm application modernization,
                    application modernization in cloud,
                    5 r's of application modernization,
                    infrastructure and application modernization with google cloud,
                    application modernization benefits,
                    mainframe application modernization,
                    aws application modernization lab,
                    legacy application modernization,
                    application modernization aws,
                    application modernization approach,
                    application modernization azure,
                    application modernization assessment,
                    application modernization assessment template,
                    application modernization and innovation deloitte,
                    application modernization and connectivity,
                    application modernization assessment tools,
                    application modernization and rationalization,
                    application modernization advanced,
                    azure application modernization,
                    aws application modernization assessment,
                    accenture application modernization,
                    azure application modernization pptx,
                    architecture and application modernization,
                    aws application modernization workshop,
                    advanced application modernization,
                    azure application modernization assessment,
                    application modernization best practices,
                    application modernization business case,
                    app modernization benefits,
                    what is application modernization,
                    application modernization process,
                    application modernization ibm,
                    application modernization architecture,
                    benefits of application modernization,
                    business case for application modernization,
                    business benefits of application modernization,
                    best practices in application modernization,
                    application modernization business value,
                    cloud application modernization,
                    application modernization enterprise,
                    application modernization explain,
                    enterprise application modernization and migration (eamm),
                    enterprise application modernization definition,
                    application modernization and migration,
                    application modernization meaning,
                    enterprise application modernization and migration,
                    application modernization for cloud,
                    application fourier transformation,
                    application modernization pdf,
                    application modernization google cloud,
                    application modernization gartner magic quadrant,
                    application modernization using generative ai,
                    application modernization healthcare"
                />
            </Helmet>

            <section className='app-mod-section-1'>
                <div className='app-mod-section-1-left'>
                    <p>Application Modernization</p>
                    <p>Effortless Migration</p>
                    <p>Through Application Modernization</p>
                </div>
                <div className='app-mod-section-1-right'>
                    <img src={brainstorming} alt='brainstorming' />
                </div>
            </section>

            <section>
                <Section2 e={section2props} />
            </section>

            <section className='app-mod-section-3'>
                <div className='app-mod-section-3-left'>
                    <p>Unitres Tech Empowers Your Business for the </p>
                    <p>Digital Age with</p>
                    <p>Seamless Application Modernization</p>
                    <p>By embracing smart modernization, you not only enhance efficiency and reduce costs but also foster innovation and growth, positioning your business to thrive in an unpredictable future. Don't just keep up with the times—stay ahead with smart modernization strategies that secure long-term success.</p>
                </div>
                <div className='app-mod-section-3-right'>
                    <img src={section3} alt="unitrestech" />
                </div>
            </section>


            <section className='app-mod-section-4'>
                <div>
                    <div className='app-mod-section-4-top'>
                        <p>How Modernization Solutions</p>
                        <p>Leverage the Power of Your Applications</p>
                        <p>3’ Industry Driven Use Cases</p>
                    </div>
                    <div className='app-mod-section-4-bottom'>
                        <div>
                            <img src={icon1} alt="" />
                            <p>Modernize your applications to protect your assets with real-time insights</p>
                            <p>Modernizing applications with real-time analytics and machine learning enhances fraud detection. Cloud-based platforms and advanced data processing allow banks to analyze transactions in real-time, detecting and preventing fraud before it happens.</p>
                        </div>
                        <div>
                            <img src={icon2} alt="" />
                            <p>Modernize and streamline your supply chain for flawless delivery</p>
                            <p>Modernizing applications with AI and advanced analytics optimizes supply chain operations. Using real-time data from shipments, inventory, and weather, logistics companies can streamline operations, reduce delays, and optimize routes.</p>
                        </div>
                        <div>
                            <img src={icon3} alt="" />
                            <p>Predict problems before they arise, ensuring seamless operations</p>
                            <p>Modernizing applications with IoT and AI enables predictive maintenance. Real-time data from sensors predicts maintenance needs, preventing unexpected breakdowns.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='app-mod-section-5'>
                <div className='app-mod-section-5-One'>
                    <p>How we modernize</p>
                    <p>legacy applications seamlessly</p>
                    <p>Take a Look at Our 12 step Modernization Process</p>
                </div>
                <CircleApplicationModernization />
            </section>

            <section className='app-mod-section-6'>
                <div className='app-mod-section-6-top'>
                    <p>unitres tech services</p>
                    <p>Leverage the Power of Your Applications with our Modernization Solutions</p>
                </div>
                <div className='app-mod-section-6-bottom'>
                    <div>
                        <img src={icon1} alt="UnitresTech" />
                        <p>Application re-engineering</p>
                        <p>We modernize legacy enterprise applications by restoring and modifying code and design. Clients gain maximum value from SOA, microservices, and serverless implementations.</p>
                    </div>
                    <div>
                        <img src={icon2} alt="UnitresTech" />
                        <p>Legacy app migration to cloud</p>
                        <p>Unitres offers three cloud migration options: application re-hosting, re-platforming, or cloud-native implementation, tailored to your application's and business's needs.</p>
                    </div>
                    <div>
                        <img src={icon3} alt="UnitresTech" />
                        <p>Regulatory compliance</p>
                        <p>Our experts ensure your apps comply with regulations like HIPAA, PCI DSS, and GDPR. We assess your software and processes, identify compliance gaps, and develop customized plans to address them.</p>
                    </div>
                    <div>
                        <img src={icon1} alt="UnitresTech" />
                        <p>Application modernization consulting</p>
                        <p>We investigate your legacy code and infrastructure, identify requirements, and provide detailed guidance to maximize the value of your previous investments.</p>
                    </div>
                    <div>
                        <img src={icon2} alt="UnitresTech" />
                        <p>Data modernization</p>
                        <p>We upgrade data management systems for fast, scalable, and secure analytics. Implementing self-service BI and advanced data visualization techniques, we enable on-demand reporting and insightful data analysis.</p>
                    </div>
                    <div>
                        <img src={icon3} alt="UnitresTech" />
                        <p>Mobile and cross-platform compatibility</p>
                        <p>Our designers and developers ensure seamless software experiences across all platforms and devices. We review your solution, identify compatibility issues, and provide remediation recommendations.</p>
                    </div>
                </div>
            </section>

            <section className='app-mod-section-7'>
                <div>
                    <img src={logo} alt="UnitresTech" />
                    <p>Still Unsure About Application Modernization for you business needs?</p>
                    <p>Let's Explore Your Options Together.</p>
                    <Button ele={contactBtnProps} />
                </div>
            </section>

            <section className='app-mod-section-8'>
                <div className='app-mod-section-8-top'>
                    <p>Types of Applications </p>
                    <p>We Modernize</p>
                </div>

                <div className='app-mod-section-8-bottom'>
                    <div>
                        <img src={section_8_1} alt="UnitresTech" loading='lazy' />
                        <p>Software Types</p>
                        <ul>
                            <li>Web, mobile, and desktop app modernization</li>
                            <li>Enterprise application modernization (ERP, CRM, BPA, HCM, SCM, PPM, BI, data warehouses) & SaaS modernization.</li>
                            <li>IoT, big data applications, blockchain, AI/ML modernization</li>
                        </ul>
                    </div>
                    <div>
                        <img src={section_8_2} alt="UnitresTech" loading='lazy' />
                        <p>Platforms</p>
                        <ul>
                            <li>Dynamics 365</li>
                            <li>Salesforce</li>
                            <li>Magento</li>
                            <li>SharePoint</li>
                            <li>Service Now and other platforms</li>
                        </ul>
                    </div>
                    <div>
                        <img src={section_8_3} alt="UnitresTech" loading='lazy' />
                        <p>Installed On</p>
                        <ul>
                            <li>Cloud-based, on-premise, and hybrid servers.</li>
                            <li>Computers, laptops, mobile devices, and wearables.</li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className='app-mod-section-9'>
                <p>with Unitres Tech always get
                    <br />
                    Everything your<strong> business needs </strong>
                    <img src={people1} alt='people1' style={{ verticalAlign: 'middle' }} />
                    and nothing it doesn’t.
                </p>
            </section>
        </div>
    )
}
