import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ScrollToTop from '../components/ScrollToTop.jsx'
import { ApiHomepage } from '../ApiGateway/pages/ApiHomepage.jsx'
import { Feature } from '../ApiGateway/pages/Feature.jsx'
import { UniTradeHomepage } from '../UniAlgoTrade/UniTradeHomepage.jsx'

export const AllRoutes2 = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/api-gateway' element={<ApiHomepage />}></Route>
                <Route path='/api-gateway/feature' element={<Feature />}></Route>
                <Route path='/UniAlgoTrade' element={<UniTradeHomepage />}></Route>
            </Routes>
        </ScrollToTop>
    )
}
