import { Box, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import section1 from '../../assets/Homepage/section-1.png'
import { useTheme } from '@emotion/react';

export const Section1 = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Box sx={{
            width: '100%',
            height: '100vh',
            m: 'auto',
            mt: '-68px',
            backgroundImage: isMobile ? `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),url(${section1})` : `linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),url(${section1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            pt: {
                xs: '100px',
                sm: '120px',
            },
        }}>
            <Box sx={{
                width: {
                    xs: '90%',
                    sm: '90%',
                    md: '40%',
                    lg: '40%',
                    xl: '40%',
                },
                m: {
                    xs: 'auto',
                    sm: 'auto',
                },
                pt: {
                    xs: 0,
                    sm: 0,
                    md: '10rem',
                    lg: '10rem',
                    xl: '10rem',
                },
                ml: {
                    md: '10%',
                    lg: '10%',
                    xl: '10%',
                },
            }}>
                <Typography component='p' sx={{
                    fontSize: {
                        xs: '30px',
                        sm: '36px',
                        md: '61.5px',
                        lg: '61.5px',
                        xl: '61.5px',
                    },
                    fontWeight: '100',
                }}
                >Make Your APIs Work Harder for You
                    <span style={{ fontWeight: "700" }}> Without the Extra Complexity</span>
                </Typography>
                <Typography sx={{
                    width: {
                        xs: '90%',
                        sm: '90%',
                        md: '60%',
                        lg: '60%',
                        xl: '60%',
                    },
                    fontSize: {
                        xs: '16px',
                        sm: '16px',
                        md: '21px',
                        lg: '21px',
                        xl: '21px',
                    },
                }}>Simplify API security, governance, and manageability for your modern applications.</Typography>
            </Box>
        </Box>
    )
}
