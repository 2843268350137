import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import '../../styles/Homepage/ServiceAccordion.css'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import icon1 from '../../assets/Homepage/service-icon-1.png'
import icon2 from '../../assets/Homepage/service-icon-2.png'
import icon3 from '../../assets/Homepage/service-icon-3.png'
import icon4 from '../../assets/Homepage/service-icon-4.png'
import modernization from '../../assets/Homepage/service-modernization.png'
import automation from '../../assets/Homepage/service-automation.png'
import dataEngg from '../../assets/Homepage/service-data-engg.png'
import cloudNative from '../../assets/Homepage/service-cloud.png'
import Button from '../Button'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : '#F3F7FF',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const ServiceAccordion = () => {
    const [expanded, setExpanded] = React.useState('panel1');
    const navigate = useNavigate()

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleService1 = () => {
        navigate('/service/application-modernization')
    }

    const handleService2 = () => {
        navigate('/service/intelligent-automation')
    }

    const handleService3 = () => {
        navigate('/service/data-engineering-analytics')
    }

    const handleService4 = () => {
        navigate('/service/cloud-engineering')
    }

    const serviceBtn1Props = {
        onClick: handleService1,
        text: 'Know More',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
    };

    const serviceBtn2Props = {
        onClick: handleService2,
        text: 'Know More',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
    };

    const serviceBtn3Props = {
        onClick: handleService3,
        text: 'Know More',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
    };

    const serviceBtn4Props = {
        onClick: handleService4,
        text: 'Know More',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
    };

    return (
        <div className='service-accordion-container'>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} >
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" >
                    <div className='accordion-summary'>
                        <img src={icon1} alt="icon-1" className='accordion-icon' />
                        <p>Modernization Expertise</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails  >
                    <div className='accordion-details-container'>
                        <img src={modernization} alt='Modernization Expertise' className='accordian-image' />
                        <div className='accordion-details-right'>
                            <p className='accordion-details-heading'>Revitalizing legacy systems, making them more agile and efficient.</p>
                            <div className='accordion-details-desc'>
                                <p><span className='accordion-detail-bold'>Modernization Expertise: </span>Upgrade legacy applications for improved maintenance and performance.</p>
                                <p><span className='accordion-detail-bold'>Tailored Cloud Migration: </span>Seamlessly transition applications to optimize scalability and efficiency in the cloud.</p>
                                <p><span className='accordion-detail-bold'>Regulatory Compliance Assurance: </span>Expert guidance ensures software compliance with key regulations.</p>
                            </div>
                            <div className='accordion-details-btn'>
                                <Button ele={serviceBtn1Props} />
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <div className='accordion-summary'>
                        <img src={icon2} alt="icon-2" className='accordion-icon' />
                        <p>Intelligent Automation</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails  >
                    <div className='accordion-details-container'>
                        <img src={automation} alt='Intelligent Automation' className='accordian-image' />
                        <div className='accordion-details-right'>
                            <p className='accordion-details-heading'>Automation promises big gains, but only when applied properly</p>
                            <div className='accordion-details-desc'>
                                <p><span className='accordion-detail-bold'>Improving customer experience: </span>in empowering transformation & driving success.</p>
                                <p><span className='accordion-detail-bold'>Higher productivity: </span>Committed to Society, Community, and a Sustainable Planet.</p>
                                <p><span className='accordion-detail-bold'>Increased performance experience: </span>Delivering Unmatched Value with Digital Expertise and Innovation.</p>
                            </div>
                            <div className='accordion-details-btn'>
                                <Button ele={serviceBtn2Props} />
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <div className='accordion-summary'>
                        <img src={icon3} alt="icon-3" className='accordion-icon' />
                        <p>Data Engineering and Analytics</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails  >
                    <div className='accordion-details-container'>
                        <img src={dataEngg} alt='Data Engineering and analytics' className='accordian-image' />
                        <div className='accordion-details-right'>
                            <p className='accordion-details-heading'>Accelerate Decision-Making with Data Engineering</p>
                            <div className='accordion-details-desc'>
                                <p><span className='accordion-detail-bold'>End to end Data Engineering Services: </span>Offers a crucial path for companies to keep pace.</p>
                                <p><span className='accordion-detail-bold'>Unmatched Expertise: </span>Accelerating feature releases within stringent timelines and cost-effective budgets.</p>
                                <p><span className='accordion-detail-bold'>Proven Accelerators for Faster time to value: </span>Revolutionize document management with Application Modernization</p>
                            </div>
                            <div className='accordion-details-btn'>
                                <Button ele={serviceBtn3Props} />
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
                    <div className='accordion-summary'>
                        <img src={icon4} alt="icon-4" className='accordion-icon' />
                        <p>Cloud Engineering</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails  >
                    <div className='accordion-details-container'>
                        <img src={cloudNative} alt='Cloud - Native Development' className='accordian-image' />
                        <div className='accordion-details-right'>
                            <p className='accordion-details-heading'>Leveraging Cloud Migration for  Optimal Business Performance</p>
                            <div className='accordion-details-desc'>
                                <p><span className='accordion-detail-bold'>Cloud Advisory and Consulting:</span> Migration and modernization strategy, Migration assessment and planning, Cloud cost management</p>
                                <p><span className='accordion-detail-bold'>Cloud Migration:</span> Application and database rehosting and remediation, Application and database re-platforming, PaaS service adoption Migration to Managed Kubernetes.</p>
                                <p><span className='accordion-detail-bold'>Cloud Modernization and Cloud-native Development:</span>Revamping monolithic apps with cloud-native containerization and hybrid cloud, Modern Database solutions adoption, Implement Cloud DevSecOps</p>
                            </div>
                            <div className='accordion-details-btn'>
                                <Button ele={serviceBtn4Props} />
                            </div>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
