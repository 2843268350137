// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App,
button {
  font-family: 'Plus Jakarta Sans';
}

:root {
  --font-size1: 1.375rem;
  --font-size2: 5.125rem;
  --font-size3: 3rem;
  --font-size4: 1.25rem;
  --font-size5: 2.25rem;
  --font-size6: 1.75rem;
  --font-size7: 1rem;
  --font-size8: 1.5rem;
  --margin-bottom-web: 10rem;
  --margin-bottom-mobile: 5rem
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;;EAEE,gCAAgC;AAClC;;AAEA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,qBAAqB;EACrB,kBAAkB;EAClB,oBAAoB;EACpB,0BAA0B;EAC1B;AACF","sourcesContent":[".App,\nbutton {\n  font-family: 'Plus Jakarta Sans';\n}\n\n:root {\n  --font-size1: 1.375rem;\n  --font-size2: 5.125rem;\n  --font-size3: 3rem;\n  --font-size4: 1.25rem;\n  --font-size5: 2.25rem;\n  --font-size6: 1.75rem;\n  --font-size7: 1rem;\n  --font-size8: 1.5rem;\n  --margin-bottom-web: 10rem;\n  --margin-bottom-mobile: 5rem\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
