import React from 'react'
import '../../styles/Homepage/Section7.css'
import people2 from '../../assets/Homepage/people-2.png'
import people3 from '../../assets/Homepage/people-3.png'
import people4 from '../../assets/Homepage/people-4.png'

export const Section7 = () => {
    return (
        <div className='section-7'>
            <div className='section-7-desc'>
                <div>
                    <p>Guiding</p>
                    <img src={people2} alt='' />
                    <p className='section-7-bold'>successful teams</p>
                    <img src={people3} alt='' />
                </div>

                <div>
                    <p>towards digital transformation</p>
                    <img src={people4} alt='' />
                    <p>and Beyond!</p>
                </div>
            </div>
        </div>
    )
}
