// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-6-container {
    margin-bottom: 10rem;
}

.bold-heading {
    font-weight: bolder;
}

.expertise-top {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.expertise-top p:nth-child(1) {
    font-size: 3rem;
    margin: 0;
}

.expertise-top p:nth-child(2) {
    font-size: 1.5rem;
}

.expertise-bottom {
    width: 60%;
    display: grid;
    margin: auto;
    justify-items: center;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

    & img {
        width: 100%;
    }
}

@media screen and (min-width:300px) and (max-width:490px) {
    .section-6-container {
        margin-bottom: var(--margin-bottom-mobile);
    }

    .expertise-top p:nth-child(1) {
        font-size: var(--font-size8);
    }

    .expertise-top p:nth-child(2) {
        font-size: 0.75rem;
    }

    .expertise-bottom {
        width: 90%;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width:601px) and (max-width:840px) {
    .expertise-top p:nth-child(1) {
        font-size: var(--font-size6);
    }

    .expertise-top p:nth-child(2) {
        font-size: 1rem;
    }

    .expertise-bottom {
        width: 80%;
        grid-template-columns: repeat(3, 1fr);
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Homepage/Section6.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,YAAY;IACZ,qBAAqB;IACrB,SAAS;IACT,qCAAqC;;IAErC;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,0CAA0C;IAC9C;;IAEA;QACI,4BAA4B;IAChC;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,UAAU;QACV,qCAAqC;IACzC;AACJ;;AAEA;IACI;QACI,4BAA4B;IAChC;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,UAAU;QACV,qCAAqC;IACzC;AACJ","sourcesContent":[".section-6-container {\n    margin-bottom: 10rem;\n}\n\n.bold-heading {\n    font-weight: bolder;\n}\n\n.expertise-top {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.expertise-top p:nth-child(1) {\n    font-size: 3rem;\n    margin: 0;\n}\n\n.expertise-top p:nth-child(2) {\n    font-size: 1.5rem;\n}\n\n.expertise-bottom {\n    width: 60%;\n    display: grid;\n    margin: auto;\n    justify-items: center;\n    gap: 1rem;\n    grid-template-columns: repeat(3, 1fr);\n\n    & img {\n        width: 100%;\n    }\n}\n\n@media screen and (min-width:300px) and (max-width:490px) {\n    .section-6-container {\n        margin-bottom: var(--margin-bottom-mobile);\n    }\n\n    .expertise-top p:nth-child(1) {\n        font-size: var(--font-size8);\n    }\n\n    .expertise-top p:nth-child(2) {\n        font-size: 0.75rem;\n    }\n\n    .expertise-bottom {\n        width: 90%;\n        grid-template-columns: repeat(2, 1fr);\n    }\n}\n\n@media screen and (min-width:601px) and (max-width:840px) {\n    .expertise-top p:nth-child(1) {\n        font-size: var(--font-size6);\n    }\n\n    .expertise-top p:nth-child(2) {\n        font-size: 1rem;\n    }\n\n    .expertise-bottom {\n        width: 80%;\n        grid-template-columns: repeat(3, 1fr);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
