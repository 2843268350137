// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel {
  position: relative;
  justify-content: center;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 0.5s ease-in-out;
  margin-left: 3%;
}

.carousel-item {
  min-width: 100%;
  box-sizing: border-box;
  padding: 20px 20px 0px 10px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}

.sec6-prt2-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #0B63E5;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
}

hr {
  border: 2px solid;
}

@media (max-width: 500px) {
  .sec6-prt2-cont>ul>li {
    line-height: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Carousel.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,sCAAsC;EACtC,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,2BAA2B;AAC7B;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;EAC3B,oCAAoC;EACpC,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;EACb,yCAAyC;EACzC,8BAA8B;AAChC;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".carousel {\n  position: relative;\n  justify-content: center;\n  width: 100%;\n  overflow: hidden;\n}\n\n.carousel-inner {\n  display: flex;\n  transition: transform 0.5s ease-in-out;\n  margin-left: 3%;\n}\n\n.carousel-item {\n  min-width: 100%;\n  box-sizing: border-box;\n  padding: 20px 20px 0px 10px;\n}\n\n.carousel-button {\n  position: absolute;\n  top: 50%;\n  transform: translateY(-50%);\n  background-color: rgba(0, 0, 0, 0.5);\n  color: white;\n  border: none;\n  padding: 10px;\n  cursor: pointer;\n  z-index: 1;\n}\n\n.prev {\n  left: 10px;\n}\n\n.next {\n  right: 10px;\n}\n\n.sec6-prt2-cont {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  background-color: #0B63E5;\n  padding: 20px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n  justify-content: space-between;\n}\n\nhr {\n  border: 2px solid;\n}\n\n@media (max-width: 500px) {\n  .sec6-prt2-cont>ul>li {\n    line-height: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
