// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CloudEngSectionSixContainer{
    display: flex;
    justify-content: space-between;
    gap: 60px;
    /* border: 1px solid; */
}

.CloudEngSectionSixContainer div{
    width: 50vh;
    flex: 1 1;
    padding: 2%;
    border: 1px solid;
    background-color: #0B63E5;
    color: white;
    border-radius: 5%;
}
.CloudEngSectionSixContainer h3{
    font-size: 28px;
    font-weight: 500;
    line-height: 40.04px;
    text-align: left;
}
.CloudEngSectionSixContainer li{
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
}

.CloudEngSectionSixContainer img{
    width: 50px;
}
hr{
    border: 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/styles/Services/CloudEngSectionSixContainer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,SAAO;IACP,WAAW;IACX,iBAAiB;IACjB,yBAAyB;IACzB,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,WAAW;AACf;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".CloudEngSectionSixContainer{\n    display: flex;\n    justify-content: space-between;\n    gap: 60px;\n    /* border: 1px solid; */\n}\n\n.CloudEngSectionSixContainer div{\n    width: 50vh;\n    flex: 1;\n    padding: 2%;\n    border: 1px solid;\n    background-color: #0B63E5;\n    color: white;\n    border-radius: 5%;\n}\n.CloudEngSectionSixContainer h3{\n    font-size: 28px;\n    font-weight: 500;\n    line-height: 40.04px;\n    text-align: left;\n}\n.CloudEngSectionSixContainer li{\n    font-size: 20px;\n    font-weight: 400;\n    line-height: 32px;\n    text-align: left;\n}\n\n.CloudEngSectionSixContainer img{\n    width: 50px;\n}\nhr{\n    border: 1px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
