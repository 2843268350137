import React, { useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import "../../styles/Services/InteAuto.css";
import inteAutosec1 from "../../assets/Services/InteAuto/inteAuto-sec1.png";
import info from "../../assets/Services/InteAuto/Info.png";
import icon5 from "../../assets/Services/InteAuto/icon5.png";
import icon7 from "../../assets/Services/InteAuto/icon7.png";
import icon_1 from "../../assets/Services/InteAuto/Icons-1.png";
import icon_2 from "../../assets/Services/InteAuto/Icons-2.png";
import icon_3 from "../../assets/Services/InteAuto/Icons-3.png";
import icons from "../../assets/Services/InteAuto/Icons.png";
import image1194 from "../../assets/Services/CloudEngg/image1194.png"
import { Section2 } from "../../components/Services/common/Section2.jsx";
import section2 from "../../assets/Services/InteAuto/InteAuto-section2-bg.png"
import Button from '../../components/Button.jsx';
import quote from '../../assets/Homepage/quote-icon.png'
import Carousel from '../../components/Carousel.jsx';
import { CircleIntelligentAutomation } from '../../components/CircleInfo/CircleIntelligentAutomation.jsx';

export default function IntelligentAuto() {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 901);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleQuote = () => {
    const footerElement = document.getElementById('sec1-prt2');
    const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - 68;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const section2props = {
    headText: 'Boost Productivity and Cut Costs with ',
    boldHeadText: '   Intelligent Process Automation.',
    desc: `Integrate your enterprise's operations, workforce, and valuable insights through the power of intelligent process automation 
    (IPA) to achieve remarkable efficiency improvements while keeping costs in check.`,
    image: section2
  }

  const quoteBtnProps = {
    onClick: handleQuote,
    text: 'Get a Quote',
    backgroundColor: '#ffffff',
    color: '#20303F',
    image: quote,
    border: '1px solid #20303F'
  };


  let slides = [
    {
      img: info,
      title: "Customer Services",
      content: [
        "Offers a crucial path for companies to keep pace."
      ]
    },
    {
      img: icon5,
      title: " Claims Handling",
      content: [
        "Streamline claims processes in the digital age with our Application Modernization solutions"
      ]
    },
    {
      img: info,
      title: "Debt Collection",
      content: [
        "accelerating feature releases within stringent timelines and cost-effective budgets."
      ]
    },
    {
      img: icon5,
      title: "Simplify Archiver",
      content: [
        "accelerating feature releases within stringent timelines and cost-effective budgets."
      ]
    },
    {
      img: icon7,
      title: "Document Handling",
      content: [
        "Revolutionize document management with Application Modernization"
      ]
    },
  ]

  return (
    <>
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
        <meta charSet="utf-8" />
        <title>Service- Intelligent Automation</title>
        <meta name="keywords" content="
          ai automation
          ai and automation
          automation and ai
          ai in automation
          ai for automation
          intelligent automation
          intelligent document processing
          automatic ai
          ai & automation
          ai business automation
          machine learning and automation
          intelligent process automation
          sap rpa
          rpa ai
          rpa and ai
          rpa with ai
          robotic process automation sap
          sap robotic automation
          sap and rpa
          robotic process automation in sap
          sap irpa
          ai marketing automation
          ai in marketing automation
          blue prism intelligent automation
          automatic intelligent
          automated document processing
          irpa sap
          ai automation services
          automation in ai
          ai document processing
          ai sales automation
          sales automation ai
          ai automation examples
          ai in industrial automation
          ai powered automation
          automation with ai
          automation using ai
          intelligent marketing automation
          ai process automation
          ai workflow automation
          intelligent document processing solutions
          ai automation software
          intelligent automation tools
          intelligent automation services
          ai driven automation
          intelligent automation solutions
          intelligent process automation software
          accenture automation offering
          automation intelligent
          sap intelligent robotic process automation
          ai automation companies
          rpa machine learning
          rpa and machine learning
          ai automation platform
          rpa with machine learning
          sap robotics
          machine learning and rpa
          machine learning in rpa
          intelligent automation meaning
          intelligent process
          sap intelligent rpa
          pega process ai
          rpa intelligent automation
          ai based automation
          intelligent automation rpa
          rpa in ai
          intelligent document processing companies
          intelligent document processing software
          intelligent document automation
          document processing automation
          intelligent document processing tools
          intelligent automation companies
          intelligent process automation examples
          sap rpa tools
          intelligent automation provides
          automation accenture
          ai ml automation
          automation ai company
          machine first and intelligent business automation
          mywizard accenture com
          ai marketing automation tools
          ai email automation
          intelligent automation platform
          cognitive document processing
          blue prism intelligent automation platform
          intelligent automation software
          ai for marketing automation
          intelligent rpa
          hyper intelligent automation
          intelligent robotic process automation
          ai powered marketing automation
          intelligent workflow
          rpa ai ml
          intelligent process automation tools
          intelligent document automation salesforce
          intelligent business automation
          ai in process automation
          ai in automation industry
          sap rpa automation
          machine learning in industrial automation
          rpa with ai and ml
          intelligent automation uipath
          machine learning industrial automation
          ai and automation in hr
          artificial automation
          capgemini intelligent automation platform quiz answers
          irpa in sap
          intelligent document processing gartner
          ai for document processing
          cognizant intelligent automation
          intelligent document processing gartner magic quadrant
          everest group intelligent document processing
          ai business process automation
          ai driven marketing automation
          cognizant intelligent process automation
          ai and marketing automation
          gartner intelligent automation
          kofax intelligent automation
          microsoft intelligent document processing
          intelligent automation gartner
          salesforce intelligent document automation
          automated document processing software
          document processing platform
          process automation ai
          pega intelligent automation
          ai document automation
          genesys intelligent automation
          intelligent document processing platform
          intelligent automation week
          ai based marketing automation
          uipath intelligent document processing
          the intelligent no code platform
          intelligent document processing open source
          intelligent automation technologies
          deloitte intelligent automation
          rpa document processing
          intelligent process automation gartner
          intelligent automation in healthcare
          intelligent automation gartner magic quadrant
          intelligent document processing in healthcare
          ericsson intelligent automation platform
          appian intelligent document processing
          ai ml rpa
          intelligent business process automation
          intelligent process automation cognizant
          sap btp rpa
          rpa and intelligent automation
          sap intelligent robotic process automation factory
          servicenow intelligent automation
          intelligent document processing insurance
          aws intelligent automation
          uipath intelligent automation
          intelligent workflow automation
          marketing automation and ai
          intelligent automation and rpa
          smart process automation
          ai for process automation
          business process automation ai
          intelligent automation consulting
          intelligent automation network
          intelligent automation in manufacturing
          ai builder document automation
          intelligent network automation
          ai for industrial automation
          intelligent automation as a service
          artificial lab automation
          sap intelligent rpa extension
          intelligent automation in retail
          ai for business automation
          examples of intelligent automation
          mortgage ai automation company
          intelligent automation in insurance
          intelligent automation process
          ai enabled automation
          forrester intelligent automation
          cortex intelligent automation
          low cost intelligent automation
          intelligent automation for healthcare
          ai automation in healthcare
          kpmg intelligent automation
          accenture intelligent automation
          ai contract automation
          intelligent automation financial services
          intelligent document process
          workfusion automation
          ai and process automation
          automation and ai in the workplace
          firtech intelligent automation
          ai in factory automation
          intelligent automation deloitte
          ai industrial automation
          machine learning automation examples
          pwc intelligent automation
          servicenow intelligent document processing
          intelligent document processing rpa
          business process automation with ai
          automation & ai
          oracle intelligent process automation
          intelligent conveyor systems
          intelligent automation ey
          intelligent automation accenture
          sap intelligent rpa factory
          deloitte intelligent document processing
          smart rpa
          intelligent automation week winter
          appian document processing
          machine learning process automation
          intelligent process automation in healthcare
          iqpc intelligent automation
          sap irpa download
          ai and intelligent automation
          sap intelligent rpa 2.0
          hyperscience ai
          intelligent document processing appian
          accenture automation platform
          intelligent rpa sap
          robotic process automation and machine learning
          robotic process automation machine learning
          hyperscience rpa
          automation using machine learning
          sap intelligent rpa store
          different types of ai and automation
          cognos deployment automation
          ai intelligent automation
          ey intelligent automation
          sap intelligent automation
          workfusion ai
          ai for factory automation
          ai ml based cognitive automation
          intelligent automation consulting services
          rpa ml ai
          blue prism abbyy
          rpa ai tools
          intelligent building management
          marketing automation using ai
          intelligent process discovery
          intelligent robotic automation
          sap intelligent rpa extension for chrome
          accenture automation offerings
          accenture mywizard platform
          ai and automation in healthcare
          ai and automation in marketing
          ai and automation meaning
          ai and automation service
          ai and iot based intelligent automation in robotics
          ai based invoice processing
          ai cognitive automation
          ai document processing software
          ai machine learning automation
          ai rpa platform
          ai rpa software
          ap intelligent automation
          appian intelligent automation
          automated document processing insurance
          automated document processing platform
          automation and intelligent system
          automation anywhere intelligent automation
          automation anywhere machine learning
          automation in accenture
          cognitive process automation examples
          cognitive robotic automation
          define intelligent automation
          define intelligent process automation
          deloitte robotics and intelligent automation
          document processing in uipath
          enterprise intelligent automation
          examples of ai automation
          examples of ai in marketing automation
          examples of intelligent process automation
          insurance intelligent automation
          intelligent automation and robotics
          intelligent automation blue prism
          intelligent automation co ltd
          intelligent automation for business
          intelligent automation hexaware
          intelligent automation in financial services
          intelligent automation in oil & gas
          intelligent automation in oil and gas
          intelligent automation insurance
          intelligent automation kpmg
          intelligent automation llc
          intelligent automation machine co ltd
          intelligent automation provides natural language processing
          intelligent automation pvt ltd
          intelligent automation pwc
          intelligent automation solutions examples
          intelligent automation systems
          intelligent automation uses which technology
          intelligent automation with vmware
          intelligent building automation systems inc
          intelligent building management system software
          intelligent business process management system
          intelligent content automation
          intelligent decision automation
          intelligent document process automation
          intelligent document processing automation
          intelligent document processing deloitte
          intelligent document processing financial services
          intelligent document processing industry
          intelligent payment automation
          intelligent privacy automation
          intelligent process automation and robotic process automation
          intelligent process automation companies
          intelligent process automation financial services
          intelligent process automation in banking
          intelligent process automation in insurance
          intelligent process automation mckinsey
          intelligent process automation meaning
          intelligent process automation platform
          intelligent process automation service
          intelligent process automation services
          intelligent process automation solutions
          intelligent process management
          intelligent process software
          intelligent ran automation
          intelligent retail automation
          intelligent rpa software
          intelligent runbook automation
          intelligent sales automation
          machine learning factory automation
          machine learning intelligent automation
          robotics & intelligent automation
          robotics and intelligent automation
          rpa & intelligent automation
          rpa & intelligent automation using python
          rpa ai and machine learning
          rpa and ai in banking
          rpa and ai in healthcare
          rpa in machine learning
          rpa is ai
          rpa natural language processing"
        />
      </Helmet>

      <div id='InteAuto-prt1'>
        <p>Intelligent Automation</p>
        <div>
          <h1>
            Unlock Efficiency With &nbsp;
            <span>
              Intelligent Automation
            </span>
          </h1>
          <img src={inteAutosec1} alt="" />
        </div>
      </div>
      <Section2 e={section2props} />
      <div id='circle-inteAuto-compo'>
        <h1>
          We automate your workflows so you can
          <br />
          <span>
            focus on bigger goals.
          </span>
        </h1>
        <p>
          Take a Look at Our 14 step Automation Process
        </p>
        <CircleIntelligentAutomation />
      </div>
      <div id='InteAuto-prt2'>
        <div className='InteAuto-section-3-left'>
          <p>
            <span>
              Unitres Tech &nbsp;
            </span>
            helps Achieve quicker outcomes with a &nbsp;
            <span>
              orward-thinking operational framework.
            </span>
          </p>
          <p></p>
          <p>Integrate your enterprise's operations, workforce, and valuable insights through the power of
            intelligent process automation (IPA) to achieve remarkable efficiency improvements while keeping costs in check.</p>
        </div>
        <div className='InteAuto-section-3-right'>
          <div className='InteAuto-section-3-right-1'>
            <div>
              <p> Evaluate</p>
              <p>Establish the right expectations and benchmarks with best-fit platforms and business cases.</p>
            </div>
            <div>
              <p>Innovate</p>
              <p>Implement the chosen platforms and processes seamlessly into your workflow.</p>
            </div>
          </div>
          <div className='InteAuto-section-3-right-2'>
            <div>
              <p>Adopt</p>
              <p>Continuously improve and optimize the adopted solutions to maximize their effectiveness.</p>
            </div>
          </div>
        </div>
      </div>
      <div id='InteAuto-prt3'>
        {
          isTablet ? (
            <>
              <div id='sec3-prt1'>
                <h1>
                  Get more done, more easily, with &nbsp;
                  <span>
                    &nbsp; intelligent process automation.
                  </span>
                </h1>
                <p>
                  5’ Industry Driven Use Cases
                </p>
              </div>
              <Carousel slides={slides} />
            </>
          ) : (
            <>
              <div id='sec3-prt1'>
                <h1>
                  Get more done, more easily, with
                  <span>
                    &nbsp;intelligent process automation.
                  </span>
                </h1>
                <p>
                  5’ Industry Driven Use Cases
                </p>
              </div>
              <div className='sec3-cont'>
                <img src={info} alt="" />
                <h2>Customer Services</h2>
                <p>Offers a crucial path for companies to keep pace.</p>
              </div>
              <div className='sec3-cont'>
                <img src={icon5} alt="" />
                <h2> Claims Handling</h2>
                <p>Streamline claims processes in the digital age with our Application Modernization solutions</p>
              </div>
              <div className='sec3-cont'>
                <img src={info} alt="" />
                <h2>Debt Collection</h2>
                <p>accelerating feature releases within stringent timelines and cost-effective budgets.</p>
              </div>
              <div className='sec3-cont'>
                <img src={icon5} alt="" />
                <h2>Simplify Archiver</h2>
                <p>accelerating feature releases within stringent timelines and cost-effective budgets.</p>
              </div>
              <div className='sec3-cont'>
                <img src={icon7} alt="" />
                <h2>Document Handling</h2>
                <p>Revolutionize document management with Application Modernization</p>
              </div>
            </>
          )
        }
      </div>
      <div id='InteAuto-prt4'>
        <div className='sec4-prt1'>
          <h1>
            How our automation solutions
            <br />
            <span>
              benefits your business
            </span>
          </h1>
          <p>The Promises of Intelligent Automation</p>
        </div>
        <div className='sec4-prt2'>
          <div>
            <img src={icon_3} alt="" />
            <h3>Improving customer experience</h3>
            <p>Offers a crucial path for companies to keep pace.</p>
          </div>
          <div>
            <img src={icons} alt="" />
            <h3>Higher productivity</h3>
            <p>Streamline claims processes in the digital age with our Application Modernization solutions</p>
          </div>
          <div>
            <img src={icon_1} alt="" />
            <h3>Increased performance experience</h3>
            <p>accelerating feature releases within stringent timelines and cost-effective budgets.</p>
          </div>
          <div>
            <img src={icon_2} alt="" />
            <h3>Reduced operating
              costs</h3>
            <p>accelerating feature releases within stringent timelines and cost-effective budgets.</p>
          </div>
        </div>
      </div>
      <div id='InteAuto-prt5'>
        <h1>
          Unitres tech
          <br />
          <span>
            services
            Achieve Efficiency with Intelligent Automation Solutions
          </span>
        </h1>
        <div id='InteAuto-sec5-cont'>
          <div id='inteauto-sec5-cont-prt1'>
            <div>
              <img src={icon_3} alt="" />
              <h3>Computer Vision ( OCR + ICR)</h3>
              <p>High-precision OCR engines for accurate text extraction from images, scanned documents, and other sources along with support for multiple languages and document types</p>
              <ul>
                <li>Document Capture and Processing</li>
                <li>OCR & ICR Technology</li>
                <li>Customized Solutions</li>
                <li>Machine Learning and Continuous Improvement</li>
              </ul>
            </div>
            <div>
              <img src={icon_3} alt="" />
              <h3>Artificial Intelligence</h3>
              <p>Unitrestech can empower businesses to harness the full potential of AI within their operations, drive innovation, and stay competitive in a rapidly evolving technological landscape Consulting Services</p>
              <ul>
                <li>AI Governance and Ethics</li>
                <li>Predictive Analytics</li>
                <li>AI Integration with Existing Systems</li>
                <li>Machine Learning Model Development</li>
                <li>Data Services</li>
              </ul>
            </div>
            <div>
              <img src={icon_3} alt="" />
              <h3>Artificial Intelligence</h3>
              <p>Unitrestech can empower businesses to harness the full potential of AI within their operations, drive innovation, and stay competitive in a rapidly evolving technological landscape Consulting Services</p>
              <ul>
                <li>AI Governance and Ethics</li>
                <li>Predictive Analytics</li>
                <li>AI Integration with Existing Systems</li>
                <li>Machine Learning Model Development</li>
                <li>Data Services</li>
              </ul>
            </div>
            <div>
              <img src={icon_3} alt="" />
              <h3>Robotic Process Automation</h3>
              <p>Automate repetitive processes across the enterprise, reducing manual tasks, increasing speed, and removing human error.</p>
              <ul>
                <li>Process Mining & Discovery</li>
                <li>Business Process Automation</li>
                <li>RPA Managed Services</li>
              </ul>
            </div>
          </div>
          <div id='inteauto-sec5-cont-prt2'>
            <h1>Automation promises big gains,
              <span>
                but only
                when applied properly
              </span>
            </h1>
            <Button ele={quoteBtnProps} />
          </div>
        </div>
      </div>
      <div id='InteAuto-prt6'>
        <h1>
          with Unitres Tech always get
          <br />
          Everything your &nbsp;
          <span>
            business needs &nbsp;
            <img src={image1194} alt="" />&nbsp;
          </span>
          and nothing it doesn’t.
        </h1>
      </div>
    </>
  )
}
