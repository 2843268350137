import bigquery from '../../assets/Techstacks/Data/bigquery.png'
import synapse from '../../assets/Techstacks/Data/synapse.png'
import databricks from '../../assets/Techstacks/Data/databricks.png'
import dataFactory from '../../assets/Techstacks/Data/data-factory.png'
import redshift from '../../assets/Techstacks/Data/redshift.png'
import snowflake from '../../assets/Techstacks/Data/snowflake.png'
import airflow from '../../assets/Techstacks/Data/airflow.png'
import dbt from '../../assets/Techstacks/Data/dbt.png'
import amazonS3 from '../../assets/Techstacks/Data/amazon-s3.png'
import hadoop from '../../assets/Techstacks/Data/hadoop.png'
import kinesis from '../../assets/Techstacks/Data/kinesis.png'
import kafka from '../../assets/Techstacks/Data/kafka.png'

export const data = [
    {
        id: 1,
        name: 'BigQuery',
        logo: bigquery
    },
    {
        id: 2,
        name: 'Synapse Analytics',
        logo: synapse
    },
    {
        id: 3,
        name: 'Databricks',
        logo: databricks
    },
    {
        id: 4,
        name: 'Data Factory',
        logo: dataFactory
    },
    {
        id: 5,
        name: 'Redshift',
        logo: redshift
    },
    {
        id: 6,
        name: 'Snowflake',
        logo: snowflake
    },
    {
        id: 7,
        name: 'Airflow',
        logo: airflow
    },
    {
        id: 8,
        name: 'DBT',
        logo: dbt
    },
    {
        id: 9,
        name: 'Amazon S3',
        logo: amazonS3
    },
    {
        id: 10,
        name: 'Hadoop',
        logo: hadoop
    },
    {
        id: 11,
        name: 'Amazon Kinesis',
        logo: kinesis
    },
    {
        id: 12,
        name: 'Kafka',
        logo: kafka
    },
]