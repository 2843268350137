import React from 'react'
import '../../styles/Homepage/Section2.css'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import techbg from '../../assets/Homepage/tech-blockchain.png'
import trlogo from '../../assets/Homepage/logo-transparent.png'
import itlogo from '../../assets/Homepage/it-logo.png'
import Button from '../Button'

export const Section2 = () => {
    const navigate = useNavigate()

    const handleAbout = () => {
        navigate('/about')
    }

    const aboutBtnProps = {
        onClick: handleAbout,
        text: 'About Us',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#292962',
        color: '#ffffff',
        border: 'none'
    };

    return (
        <div className='section-2-container'>
            <img src={techbg} alt='technology-blockchain' className='blockchain-bg' />
            <div className='section-2'>
                <img src={trlogo} alt='unitrestech' />
                <p className='section-2-line1'>WHO ARE WE?</p>
                <p className='section-2-line2'>Your Trusted Partner In Staying Ahead With</p>
                <p className='section-2-line3'>Transformative Technology.</p>
                <p className='section-2-line4'>UNITRES empowers companies to embrace cutting-edge technologies tailored to evolving business needs.</p>
                <div className='section-2-bottom'>
                    <Button ele={aboutBtnProps} />
                    <img src={itlogo} alt='unitrestech' className='itlogo' />
                </div>
            </div>
        </div>
    )
}
