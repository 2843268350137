import React from 'react';
import uniAlgoWebImage from "../assets/UniAlgoTrade.png";
import Coming from "../assets/ComingSoon.png";
import { Helmet } from 'react-helmet';

export const UniTradeHomepage = () => {
    return (
        <>
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
                <meta charSet="utf-8" />
                <title>UniAlgoTrade</title>
            </Helmet>

            <div>
                <img
                    src={uniAlgoWebImage}
                    alt="UniAlgoTrade"
                    style={{
                        width: "100%",
                        filter: "blur(2px)"
                    }}
                />
                <img
                    src={Coming}
                    alt="Coming Soon"
                    style={{
                        zIndex: 1,
                        width: "100%",
                        position: "fixed",
                        top: 0,
                        left: 0
                    }}
                />
            </div>
        </>
    );
};
