import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from "react-helmet";
import "../../styles/Services/CloudEng.css"
import section1Img from "../../assets/Services/CloudEngg/Cloud-data-processing.png"
import personUsingAITool from "../../assets/Services/CloudEngg/person-using-ai-tool-job (1) 1.png"
import icon1 from "../../assets/Services/CloudEngg/Icons1.png"
import icon4 from "../../assets/Services/CloudEngg/Icons4.png"
import icon7 from "../../assets/Services/CloudEngg/Icons7.png"
import icon2 from "../../assets/Services/CloudEngg/Icons2.png"
import icon5 from "../../assets/Services/CloudEngg/Icons5.png"
import icon3 from "../../assets/Services/CloudEngg/Icons3.png"
import icon6 from "../../assets/Services/CloudEngg/Icons6.png"
import icon8 from "../../assets/Services/CloudEngg/Icons8.png"
import logo2 from "../../assets/logo-2.png"
import tbl1Icon from "../../assets/Services/CloudEngg/cld-section5-table1-icon.png"
import tbl2Icon from "../../assets/Services/CloudEngg/cld-section5-table2-icon.png"
import tbl3Icon from "../../assets/Services/CloudEngg/cld-section5-table3-icon.png"
import ArrowRgt from "../../assets/Services/CloudEngg/Arrow6.png"
import image1194 from "../../assets/Services/CloudEngg/image1194.png"
import { Section2 } from "../../components/Services/common/Section2.jsx";
import section2 from "../../assets/Services/CloudEngg/Clound-section2-bg.png"
import Button from '../../components/Button';
import Carousel from '../../components/Carousel';
import { CloudEngSectionSix } from '../../components/CloudEngSectionSix.jsx';
export const CloudEngg = () => {
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1282);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleContact = () => {
    const footerElement = document.getElementById('sec1-prt2');
    const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - 68;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

  const contactBtnProps = {
    onClick: handleContact,
    text: 'Get in Touch',
    icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
    backgroundColour: '#20303F',
    color: '#ffffff',
  };

  const section2props = {
    headText: 'Leveraging Cloud Migration for ',
    boldHeadText: '  Optimal Business Performance',
    desc: `Unitres comprehensively guides enterprises through their cloud 
    transformations. Modernizing outdated systems is essential for progress in the digital era, with cloud technologies at the core. `,
    image: section2
  }

  const slides = [
    {
      img: tbl1Icon,
      title: "Assess Your Enterprise and Discover the Best Path Forward",
      content: [
        "Evaluate client tech, tailor cloud strategy, choose fitting tech and commercial options, and align with transformative goals."
      ]
    },
    {
      img: tbl2Icon,
      title: "Adopt Cloud Computing and Unlock Its Full Potential",
      content: [
        "Accelerate enterprise cloud adoption with swift hosting, re-platforming, and top-tier PaaS integration.",
        "Our automation-driven approach minimizes effort and accelerates goal achievement."
      ]
    },
    {
      img: tbl3Icon,
      title: "Modernize and Transform Applications to Cloud-Native Services",
      content: [
        "We modernize legacy systems with cloud-native solutions, including APIs, microservices, containers, and NoSQL databases.",
        "Our Customized Application Development platform accelerates transformation through data extraction and hyper-automation, reducing reliance on specialized skills."
      ]
    }
  ];


  return (
    <>
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
        <meta charSet="utf-8" />
        <title>Service - Cloud Engineering</title>
        <meta name="keywords" content="
          cloud and computing,
          cloud cloud computing,
          cloud to cloud,
          cloud,
          gcp google,
          gcp google cloud,
          gcp google cloud platform,
          cloud google cloud,
          google google cloud,
          google cloud software,
          cloud computing google cloud,
          google google cloud platform,
          google logging,
          google cloud console,
          cloud gcp,
          cloud platform,
          gcp cloud platform,
          cloud security,
          data cloud,
          cloud and security,
          security of cloud computing,
          cloud it security,
          it security cloud,
          google cloud platform,
          google cloud plat,
          managed cloud services,
          google cloud computing platform,
          cloud virtual,
          google documentation,
          cloud and aws,
          managed cloud it services,
          managed it cloud services,
          cloud services,
          cloud it services,
          google cloud servers,
          architecture of cloud computing,
          cloud architecture in cloud computing,
          cloud based architecture,
          google cloud platform servers,
          cloud architecture,
          google cloud certification,
          gcp certification,
          cloud engineer,
          cloud management tool,
          cloud management tooling,
          gcp full form,
          cloud login,
          google api,
          cloud managed service provider,
          cloud managed it services,
          cloud and managed services,
          cloud computing managed services,
          cloud based managed services,
          api of google,
          google cloud p,
          cloud console,
          cloud technology,
          gcp login,
          hybrid cloud,
          cloud servers,
          cloud computing services,
          private cloud,
          private cloud computing,
          cloud security services in cloud computing,
          gcp log in,
          hybrid cloud technology,
          cloud computing servers,
          cloud technology services,
          cloud projects,
          cloud computing and cloud services,
          hybrid cloud in cloud computing,
          cloud based computing services,
          newsletter cloud,
          cloud platform login,
          computing cloud services,
          google partners in india,
          cloud services and security,
          hybrid cloud application,
          application for cloud computing,
          projects in cloud computing,
          cloud service providers,
          cloud computing service providers,
          cloud based service providers,
          cloud technology providers,
          cloud security data,
          google server,
          google big query,
          cloud infrastructure,
          google bigquery,
          google cloud security products,
          cloud data services,
          data cloud services,
          infrastructure cloud computing,
          cloud based infrastructure,
          cloud infra,
          cloud and infrastructure,
          cloud it infrastructure,
          cloud certified,
          cloud & infrastructure,
          cloud app,
          cloud providers,
          cloud management,
          cloud computing providers,
          cloud application,
          cloud cybersecurity,
          data science on the google cloud platform,
          provider cloud,
          cybersecurity and the cloud,
          provider cloud computing,
          cloud based providers,
          cloud software management,
          google partner,
          google cloud login,
          google cloud training,
          google cloud platform training,
          google gcp training,
          gcp data engineer,
          google gcp login,
          enterprise cloud computing,
          cloud google training,
          cloud for enterprise,
          google cloud class,
          google cloud computing training,
          google cloud developer training,
          google platform training,
          login google cloud platform,
          login to google cloud platform,
          google platform login,
          google partner company,
          google cloud services,
          cloud solutions,
          google cloud platform services,
          gcp cloud,
          google cloud computing services,
          solution cloud,
          google cloud sql,
          cloud tech,
          google cloud technology,
          cloud computing solutions,
          google cloud based services,
          service google cloud,
          cloud networks,
          cloud data base,
          online computing,
          cloud computing database,
          cloud google,
          cloud software,
          cloud based,
          google computing,
          google cloud skill,
          cloud infrastructure security,
          cloud security in cloud computing,
          gcp interview questions,
          cloud learning platforms,
          big data in cloud computing,
          gcp video,
          google cloud logo,
          big data on cloud,
          cloud computing cloud security,
          google cloud computing,
          google cloud hosting,
          gcp security,
          google cloud price,
          google project,
          google cloud associate cloud engineer,
          gcp associate cloud engineer,
          cloud developer,
          google and cloud,
          cloud data engineer,
          google cloud computing pricing,
          cloud computing platform,
          about cloud computing,
          business cloud computing,
          cloud platform security,
          cloud based platform,
          cloud security solution,
          code cloud,
          sap cloud computing,
          google cloud managed service provider,
          google ide,
          cloud monitoring in cloud computing,
          cloud technology for business,
          gcp services,
          gcp cloud services,
          cloud company,
          google cloud sdk,
          google database,
          cloud companies,
          associate cloud engineer,
          google vision,
          future of cloud computing,
          cloud architect courses,
          cloud services in cloud computing,
          google computing engine,
          cloud service management,
          company cloud,
          future of cloud,
          cloud computing how it works,
          database on google,
          gae google,
          google cloud study jam,
          learn google cloud,
          cloud monitoring,
          google cloud api,
          google cloud platform api,
          learn cloud computing,
          learn google cloud platform,
          cloud based software,
          cloud computing training,
          cloud security engineer,
          google cloud game,
          cloud jobs,
          google platform api,
          cloud infrastructure services,
          cloud computing company,
          cloud client,
          cloud based companies,
          cloud technologies list,
          api cloud google,
          api google cloud platform,
          cloud technology training,
          cloud computing monitoring,
          learn cloud technology,
          cloud data security solutions,
          training gcp,
          cloud and infrastructure services,
          security cloud engineer,
          google cloud partner,
          google cloud security,
          google cloud platform security,
          cloud consultant,
          google associate cloud engineer,
          gcp bigquery,
          gcp architecture,
          google cloud platform partners,
          cloud computing courses google,
          cloud solutions services,
          cloud based service,
          cloud deployment,
          install gcloud,
          cloud service providers in cloud computing,
          cloud partner google,
          cloud computing companies in india,
          cloud companies in india,
          cloud services and solutions,
          google build,
          cloud based companies in india,
          working on cloud computing,
          consulting cloud,
          working in the cloud,
          cloud based it services,
          google platform,
          google cloud project,
          google cloud engineer,
          google cloud architect,
          gcp price,
          cloud identity,
          cloud data,
          cloud build,
          hybrid cloud computing,
          cloud api,
          cloud operations,
          google cloud security solutions,
          google cloud platform projects,
          gcp cloud pricing,
          cloud game google,
          cloud id,
          cloud service providers in india,
          cloud work,
          gcp training in hyderabad,
          cloud application monitoring,
          google cloud platform user management,
          cloud platform pricing,
          cloud app monitoring,
          google cloud jobs,
          google cloud certified associate cloud engineer,
          cloud marketplace,
          google cloud tech,
          gcp big query,
          cloud learning,
          gcp documentation,
          best cloud service provider,
          cloud price,
          cloud infrastructure engineer,
          google cloud platform jobs,
          cloud data analytics,
          cloud platform services,
          jobs at google cloud,
          google cloud compliance,
          cloud infrastructure management,
          cloud code,
          google media,
          cloud computing case study,
          google cloud platform compliance,
          google cloud interview questions,
          cloud case studies,
          best cloud computing service providers,
          documentation gcp,
          cloud in healthcare industry,
          google apis login,
          google cloud account,
          learn gcp,
          google cloud developer,
          google cloud bigquery,
          google cloud big query,
          gcp cdn,
          google cdn,
          cloud system,
          top cloud service providers,
          cloud services for small business,
          google run,
          google cloud dev,
          python cloud,
          google solutions,
          google cloud platform developer,
          gcp cloud digital leader,
          python cloud computing,
          google big,
          digital transformation cloud,
          cloud platform management,
          google cloud bigquery,
          cloud based app,
          hpc cloud providers,
          computing services,
          cloud computing digital transformation,
          digital transformation cloud computing,
          cloud computing and digital transformation,
          partner find,
          logging in the cloud,
          cloud tool,
          cloud computing services for small business,
          development in the cloud,
          gcp account,
          gcp cloud run,
          cloud account,
          google cloud vm,
          google cloud app,
          gcp marketplace,
          google cloud platform pricing,
          gcp jobs,
          cloud search,
          google cloud application,
          google cloud data engineer,
          cloud transformation,
          google cloud platform vm,
          google cloud documentation,
          cloud services company,
          list of cloud service providers,
          gcp projects,
          vm on google cloud,
          gcp developer,
          cloud adoption,
          cloud based monitoring,
          google cloud price india,
          cloud computing consultant,
          google cloud platform applications,
          aws azure gcp,
          billing account,
          different cloud services,
          cloud transformation services,
          industry cloud platform,
          bigquery in gcp,
          migrating application to cloud,
          all about cloud computing,
          google transformation,
          cloud vendor,
          google service account,
          logging cloud,
          google award,
          certified cloud architect,
          google cloud competition,
          google cloud platform data engineer,
          big query in gcp,
          cloud providers in cloud computing,
          cloud for data,
          app google cloud,
          cloud based services companies,
          transformation cloud,
          various cloud services,
          cloud computing vendor,
          cloud computing services company,
          service account in gcp,
          cloud company in bangalore,
          cloud service vendor,
          list of cloud computing platforms,
          vendor cloud computing,
          google cloud sign up,
          google cloud platform sign up,
          google cloud support,
          gcp services list,
          gcp storage,
          sign up for google cloud platform"
        />
      </Helmet>
      <div id='Cld-eng-sec-1'>
        <p className='head-part1'>Cloud Engineering</p>
        <div className='head-part2'>
          <div>
            <h1>
              Unleash the Power Of &nbsp;
              <span className='textBolder'>
                Cloud- Native Development
              </span>
            </h1>
          </div>
          <div>
            <img src={section1Img} alt='section1Img' />
          </div>
        </div>
      </div>
      <Section2 e={section2props} />
      <div id='Cld-eng-sec-2'>
        <div>
          <h1>
            Leveraging Cloud Migration For
            &nbsp;
            <span>
              Optimal Business Performance
            </span>
          </h1>
          <p>
            Unitres comprehensively guides enterprises through their
            cloud transformations. Modernizing outdated systems is essential
            for progress in the digital era, with cloud technologies at the core.
          </p>
        </div>
        <div>
          <img src={personUsingAITool} alt="" />
        </div>
      </div>
      <div id='Cld-eng-sec-3'>
        <div>
          <h1>
            How choosing the right cloud computing <br />
            <span>
              boosts your business's competitive edge.
            </span>
          </h1>
          <p>
            8' Industry Driven Use Cases
          </p>
        </div>
        <div id='sec3-cont'>
          {isTablet ? (
            <div style={{ width: "100%" }} className="box-container">
              <div className='sec3-prt2'>
                <img src={icon1} alt="" />
                <h1>Cost Efficiency</h1>
                <p>
                  . Pay-as-You-Go Model: Users pay only for the resources they consume,
                  allowing for more efficient cost management.
                </p>
                <p>
                  . Reduced Capital Expenditure: Eliminates the need for large upfront investments
                  in hardware and infrastructure.
                </p>
              </div>
              <div className='sec3-prt2'>
                <img src={icon4} alt="" />
                <h1>Enhanced Security</h1>
                <p>
                  Professional Expertise: Cloud providers invest heavily in security measures and employ dedicated teams of
                  experts to protect data.
                </p>
                <p>
                  Data Encryption: Data transmission and storage are often encrypted to enhance security.
                </p>
              </div>
              <div className='sec3-prt2'>
                <img src={icon7} alt="" />
                <h1>Easy to consume</h1>
                <p>
                  Cloud improves agility with standardized “appliance-like” service that can be
                  provisioned in minutes in an aaSOpex model, saving approximately 50% in start-up time.
                </p>
              </div>
              <div className='sec3-prt2'>
                <img src={icon2} alt="" />
                <h1>Cloud operating model</h1>
                <p>
                  More efficient cloud operating models are powered by analytics, automation,
                  and AI, with a saving of approximately 30%-50% in ongoing run operations.
                </p>
              </div>
              <div className='sec3-prt2'>
                <img src={icon5} alt="" />
                <h1>Innovation and Competitive Edge</h1>
                <p>
                  Focus on Core Competencies: Allows businesses to focus on their
                  core activities, leaving the management of IT infrastructure to specialized providers.
                </p>
              </div>
              <div className='sec3-prt2'>
                <img src={icon3} alt="" />
                <h1>Flexibility and Agility</h1>
                <p>
                  Rapid Deployment: Quickly provision and deploy services without
                  the delays associated with traditional infrastructure setup.
                </p>
              </div>
              <div className='sec3-prt2'>
                <img src={icon6} alt="" />
                <h1>Scalability</h1>
                <p>
                  On-Demand Resources: Easily scale up or down based on changing workloads,
                  ensuring optimal performance and resource utilization.
                </p>
              </div>
              <div className='sec3-prt2'>
                <img src={icon8} alt="" />
                <h1>Reliability and Availability</h1>
                <p>
                  Redundancy: Cloud providers often have multiple data centers across different
                  geographic locations, reducing the risk of service downtime.
                  Service Level Agreements (SLAs): Providers offer SLAs that guarantee a certain level of service
                </p>
              </div>
            </div>
          ) : (
            <>
              <div style={{ width: "90%" }}>
                <div className='sec3-prt2'>
                  <img src={icon1} alt="" />
                  <h1>Cost Efficiency</h1>
                  <p>
                    . Pay-as-You-Go Model: Users pay only for the resources they consume,
                    allowing for more efficient cost management.
                  </p>
                  <p>
                    . Reduced Capital Expenditure: Eliminates the need for large upfront investments
                    in hardware and infrastructure.
                  </p>
                </div>
                <div className='sec3-prt2'>
                  <img src={icon4} alt="" />
                  <h1>Enhanced Security</h1>
                  <p>
                    Professional Expertise: Cloud providers invest heavily in security measures and employ dedicated teams of
                    experts to protect data.
                  </p>
                  <p>
                    Data Encryption: Data transmission and storage are often encrypted to enhance security.
                  </p>
                </div>
                <div className='sec3-prt2'>
                  <img src={icon7} alt="" />
                  <h1>Easy to consume</h1>
                  <p>
                    Cloud improves agility with standardized “appliance-like” service that can be
                    provisioned in minutes in an aaSOpex model, saving approximately 50% in start-up time.
                  </p>
                </div>
              </div>
              <div style={{ width: "90%" }}>
                <div className='sec3-prt2'>
                  <img src={icon6} alt="" />
                  <h1>Scalability</h1>
                  <p>
                    On-Demand Resources: Easily scale up or down based on changing workloads,
                    ensuring optimal performance and resource utilization.
                  </p>
                </div>
                <div className='sec3-prt2'>
                  <img src={icon8} alt="" />
                  <h1>Reliability and Availability</h1>
                  <p>
                    Redundancy: Cloud providers often have multiple data centers across different
                    geographic locations, reducing the risk of service downtime.
                    Service Level Agreements (SLAs): Providers offer SLAs that guarantee a certain level of service
                  </p>
                </div>
              </div>
              <div style={{ width: "90%" }}>
                <div className='sec3-prt2'>
                  <img src={icon2} alt="" />
                  <h1>Cloud operating model</h1>
                  <p>
                    More efficient cloud operating models are powered by analytics, automation,
                    and AI, with a saving of approximately 30%-50% in ongoing run operations.
                  </p>
                </div>
                <div className='sec3-prt2'>
                  <img src={icon5} alt="" />
                  <h1>Innovation and Competitive Edge</h1>
                  <p>
                    Focus on Core Competencies: Allows businesses to focus on their
                    core activities, leaving the management of IT infrastructure to specialized providers.
                  </p>
                </div>
                <div className='sec3-prt2'>
                  <img src={icon3} alt="" />
                  <h1>Flexibility and Agility</h1>
                  <p>
                    Rapid Deployment: Quickly provision and deploy services without
                    the delays associated with traditional infrastructure setup.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div id='Cld-eng-sec-4'>
        <div id='sec4-prt1'>
          <div>
            <img src={logo2} alt="" />
            <h1>
              Still Unsure About Cloud Migration for you business? <br />
              <span> Let's Explore Your Options Together.</span>
            </h1>
            <Button ele={contactBtnProps} />
          </div>
        </div>
        <div id='sec4-prt2'>
          <div>
            <h1>
              services
              <br />
              <span>Unleash Potential through Cloud Migration Strategies</span>
            </h1>
          </div>
        </div>
      </div>
      <div id='Cld-eng-sec-5'>
        <div id='sec5-prt1'>
          <div className='table'>
            <div className='sec5-tbl-top'>
              <img src={tbl1Icon} alt="tbl1Icon" className='sec5-tbl-commonimg' />
              <h3>Cloud Advisory and Consulting</h3>
            </div>
            <hr />
            <div>
              <ul>
                <li>Migration and modernization strategy</li>
                <li>Migration assessment and planning</li>
                <li>Cloud cost management</li>
              </ul>
            </div>
          </div>
          <div>
            <div className='table'>
              <div className='sec5-tbl-top'>
                <img src={tbl2Icon} alt="" className='sec5-tbl-commonimg' />
                <h3>Cloud Migration</h3>
              </div>
              <hr />
              <div>
                <ul>
                  <li>Application and database rehosting and remediation</li>
                  <li>Application and database re-platforming</li>
                  <li>PaaS service adoption Migration to Managed Kubernetes</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div id='sec5-prt2'>
          <div className='table'>
            <div className='sec5-tbl-top'>
              <img src={tbl3Icon} alt="" className='sec5-tbl-commonimg' />
              <h3>Cloud Modernization and Cloud-native Development</h3>
            </div>
            <hr />
            <div>
              <ul>
                <li>Revamping monolithic apps with cloud-native containerization and hybrid cloud.</li>
                <li>Modern Database solutions adoption</li>
                <li>Implement Cloud DevSecOps</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div id='Cld-eng-sec-6'>
        <h1>
          Cloud engineering done &nbsp;
          <span>right with Unitres Tech</span>
        </h1>
        <div id='sec6-prt1'>
          <p>Assess</p>
          <img src={ArrowRgt} alt="" />
          <p>Adopt</p>
          <img src={ArrowRgt} alt="" />
          <p>Transform</p>
        </div>
        {
          isTablet ? (
            <Carousel slides={slides} />
          )
            : (
              <CloudEngSectionSix />
            )
        }
        <div id='sec6-prt3'>
          <h1>
            with Unitres Tech always get
            Everything your &nbsp;
            <span>
              business needs &nbsp;
              <img src={image1194} alt="" />&nbsp;
            </span>
            and nothing it doesn’t.
          </h1>
        </div>
      </div>
    </>
  )
}
