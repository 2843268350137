import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import '../styles/About.css'
import Meeting from '../assets/About/Meeting.jpg'
import Rocket from '../assets/About/Rocket.png'
import HadnShake from '../assets/About/HadnShake.png'
import logo from '../assets/Homepage/logo-transparent.png'
import Vector from '../assets/About/Vector.png'
import motivatesomeone from '../assets/About/motivatesomeone.png'
import HandshakeLogo from '../assets/About/HandshakeLogo.png'
import StepS from '../assets/About/StepS.png'
import Santosh from '../assets/About/Santosh.png';
import Nivedita from '../assets/About/Nivedita.png';
import Suhas from '../assets/About/Suhas.png';
import ITLogo from '../assets/About/ITLogo.png';
import GoogleCloud from '../assets/About/GoogleCloud.png';
import SnowFlake from '../assets/About/SnowFlake.png';
import Relation from '../assets/About/Relation.png'
import Stack from '../assets/About/Stack.png'
import arcVector from '../assets/About/arcVector.png'
import FlagVector from '../assets/About/FlagVector.png'
import LinkedIn from '../assets/About/LinkedIn.png'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from '../components/Button';


export const AboutUs = () => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

    const scrollToFooter = () => {
        const footerElement = document.getElementById('sec1-prt2');
        const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 68;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    const buttonProps = {
        onClick: scrollToFooter,
        text: 'Get Connected',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColour: '#20303F',
        color: '#ffffff',
        width: "500px",
        border: "none"
    };


    const handleMeeting = () => {
        const footerElement = document.getElementById('sec1-prt2');
        const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 68;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    const buttonPropsTwo = {
        onClick: handleMeeting,
        text: 'Book a Meeting',
        backgroundColor: '#ffffff',
        color: '#20303F',
        image: Meeting,
        border: '2px solid #20303F'
    };

    const handleCareer = () => {
        navigate('/careers')
    };

    const buttonPropsCareer = {
        onClick: handleCareer,
        text: 'View Careers',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColour: '#20303F',
        color: '#ffffff',
        border: "none"
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1281);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
                <meta charSet="utf-8" />
                <title>UnitresTech - About Us </title>
            </Helmet>
            <div className='about-container'>
                {/* One */}
                <div className='about-containerOne'>
                    <div className='about-container-one'>
                        <p className='about-container-subone'>ABOUT US</p>
                        <p className='about-container-subtwo'>Where Vision</p>
                        <p className='about-container-subthree'>Meets Execution</p>
                        <p className='about-container-subfour'>Forward-thinking strategists shaping the future with innovative, impactful solutions.</p>

                        <div className="about-container-subfive">
                            {/* <Button ele={buttonPropsOne} /> */}
                            <Button ele={buttonPropsTwo} />
                        </div>
                    </div>

                    <div className='about-container-two'>
                        <img src={Rocket} alt="Rocket" />
                    </div>
                </div>

                {/* Two */}
                <div className='about-containerTwo'>
                    <img src={HadnShake} alt='HadnShake' className='HadnShakeimg' />
                    <div className='about-containerTwo-one'>
                        <img src={logo} alt='logo' className='logoT' />
                        <p className='about-containerTwo-line1'>OUR VISION</p>
                        <p className='about-containerTwo-line2'>We Believe In Turning Ideas Into Reality <span className='homepage-content-one-line3'>Through Precise Execution And Our Dedicated Team.</span>
                        </p>
                        <div className='about-containerTwo-bottom' onClick={scrollToFooter}>
                            <Button ele={buttonProps} />
                            <img src={Vector} alt='Vector' className='Vector' />
                        </div>
                    </div>
                </div>


                {/* Three */}
                <div className='about-containerThree'>
                    <div className='about-containerThree-Container'>
                        <p className='about-containerThree-Line1'>OUR MISSION</p>
                        <p className='about-containerThree-Line2'> <span className='about-containerThree-Line3'>UNITRES</span> empowers companies to embrace cutting-edge technologies tailored to evolving business needs.
                            <span className='about-containerThree-Line4'>Our intelligent talent acquisition solutions guarantee top-tier experiences in Technology.
                            </span>
                        </p>
                        <div className='about-containerThree-Container2'>
                            <img src={motivatesomeone} alt="motivatesomeone" />
                        </div>
                    </div>

                    <div className='about-containerThree-three'>
                        <div className='about-containerThree-threeone-logo'><img src={HandshakeLogo} alt="HandshakeLogo" /></div>
                        <div className='about-containerThree-threeone'>
                            <div><img src={StepS} alt="StepS" /> </div>
                            <div>
                                <p className='about-containerThree-threeone-line1'>HOW IT STARTED</p>
                                <p className='about-containerThree-threeone-line2'>Established in January 2019, UNITRES aims to be your top choice for outsourcing software solutions and staffing needs.</p>
                                <p className='about-containerThree-threeone-line2' style={{}}> Seasoned experts are dedicated to driving your success with top-notch technical solutions and resources.</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Four */}
                <div className='about-containerFour'>
                    <div className='about-containerFour-headLines'>
                        <p>Forward-thinking strategists
                            <span className='about-containerFourPhoto'><img src={Nivedita} alt="Nivedita" /></span>
                            <span style={{ fontWeight: "500" }}> shaping the future </span>
                            <span className='about-containerFourPhoto'><img src={Suhas} alt="Suhas" /></span>
                            with innovative
                            <span className='about-containerFourPhoto'><img src={Santosh} alt="Santosh" /></span>
                            and impactful solutions!
                        </p>
                    </div>

                    <div>
                        <hr style={{ width: "70%", marginTop: "var(--margin-bottom-web)", marginBottom: "-30px" }} />
                        <div>
                            <p className='about-containerFourbottom'>Top-Tier
                                <span className='about-containerFourbottom-IT'> <img src={ITLogo} alt="ITLogo" /> </span>
                                <span className='about-containerFourbottompart2'> Industry Partners</span>
                            </p>
                        </div>
                        <div className='about-containerFourbottom2'>
                            <img src={GoogleCloud} alt="GoogleCloud" />
                            <img src={SnowFlake} alt="SnowFlake" />
                        </div>
                    </div>
                </div>

                {/* Five */}
                <div className='about-containerFive'>
                    <div className='about-containerFivePartone'>
                        <div><p>ENGINEERING</p></div>
                        <div><p>DESIGN</p></div>
                        <div><p>PRODUCT</p></div>
                        <div><p>TALENT ACQUISITION</p></div>
                        <div><p>IN-HOUSE DEVELOPMENT</p></div>
                    </div>
                    <div className='about-containerFiveParttwo'>
                        <div><p>DATA ENGINEERING</p></div>
                        <div><p>AUTOMATION</p></div>
                        <div><p>APPLICATION MODERNIZATION</p></div>
                    </div>
                </div>

                {/* Six */}
                <div className='about-containerSix'>
                    <div className='about-containerSix-one'>
                        <div className='about-containerSix-one-info'>
                            <p className='about-containerSix-one-Title'>how we do it better</p>
                            <p className='about-containerSix-one-Subtitle'>Because Your Business
                                Deserves The Best
                            </p>
                        </div>
                        <div className='about-containerSix-one-data'>
                            <div>
                                <img src={Stack} alt="Stack" className='dataimg' />
                                <div className='about-containerSix-one-data-media'>
                                    <div><p className='about-containerSix-one-dataTitle'>Your Trusted Tech Partner</p></div>
                                    <div><p className='about-containerSix-one-datasubtitle'>in empowering transformation & driving success</p></div>
                                </div>
                            </div>
                            <div>
                                <img src={arcVector} alt="arcVector" className='dataimg' />
                                <div className='about-containerSix-one-data-media'>
                                    <div><p className='about-containerSix-one-dataTitle'>Pioneering Business Solutions</p> </div>
                                    <div><p className='about-containerSix-one-datasubtitle'>Delivering Unmatched Value with Digital Expertise and Innovation.</p> </div>
                                </div>
                            </div>
                            <div>
                                <img src={FlagVector} alt="FlagVector" className='dataimg' />
                                <div className='about-containerSix-one-data-media'>
                                    <div><p className='about-containerSix-one-dataTitle'>Beyond Business Sucess</p> </div>
                                    <div><p className='about-containerSix-one-datasubtitle'>Committed to Society, Community, and a Sustainable Planet</p> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='about-containerSix-two'>
                        <img src={Relation} alt="Relation" />
                    </div>
                </div>

                {/* Seven */}
                <div className='about-containerSeven'>
                    <div className='about-containerSeven-one'>
                        <p className='about-containerSeven-oneTitle'>meet the leadership team</p>
                        <p className='about-containerSeven-oneSubtitle'>Impacting the future</p>
                    </div>

                    <div id={isMobile ? 'marquee' : ''} className='about-containerSeven-two'>
                        {/* 1 */}
                        <div className='about-containerSeven-twoBlog'>
                            <div className='about-containerSeven-two-blog'>
                                <div><p>Passionate Tech Leader</p></div>
                                <div onClick={() => {
                                    const linkedinUrl = 'https://in.linkedin.com/in/santoshshettyunitres';

                                    if (linkedinUrl) {
                                        window.open(linkedinUrl, '_blank');
                                    } else {
                                        console.error('LinkedIn URL is undefined');
                                    }
                                }}><img src={LinkedIn} alt="LinkedIn" /></div>
                            </div>
                            <div className='about-containerSeven-twoBlog-info'>
                                <p>Diverse domains expertise , including Banking, Insurance, and Payments. With   a global perspective, successfully navigated through various geographies, such as the USA, Africa, UAE, and Europe.</p>
                            </div>
                            <div className='about-containerSeven-twoBlog-Person'>
                                <div>
                                    <img src={Santosh} alt="Santosh" />
                                </div>
                                <div>
                                    <div className='about-containerSeven-twoBlogName'><p>Santosh Shetty</p></div>
                                    <div className='about-containerSeven-twoBlogPosition'><p>CEO</p></div>
                                </div>
                            </div>
                        </div>

                        {/* 2 */}
                        <div className='about-containerSeven-twoBlog'>
                            <div className='about-containerSeven-two-blog'>
                                <div><p>Experienced Cloud Engineer</p></div>
                                <div
                                    onClick={() => {
                                        const linkedinUrl = 'https://in.linkedin.com/in/suhas-birje-a6294243';

                                        if (linkedinUrl) {
                                            window.open(linkedinUrl, '_blank');
                                        } else {
                                            console.error('LinkedIn URL is undefined');
                                        }
                                    }}
                                ><img src={LinkedIn} alt="LinkedIn" /></div>
                            </div>
                            <div className='about-containerSeven-twoBlog-info'>
                                <p>His global experience and collaboration with Fortune 500 clients underscore his ability to drive successful outcomes in diverse and challenging environments.</p>
                            </div>
                            <div className='about-containerSeven-twoBlog-Person'>
                                <div>
                                    <img src={Suhas} alt="Suhas" />
                                </div>
                                <div>
                                    <div className='about-containerSeven-twoBlogName'><p>Suhas Birje</p></div>
                                    <div className='about-containerSeven-twoBlogPosition'><p>Chief Technologist</p></div>
                                </div>
                            </div>
                        </div>

                        {/* 3 */}
                        <div className='about-containerSeven-twoBlog'>
                            <div className='about-containerSeven-two-blog'>
                                <div><p>Dedicated HR Strategist</p></div>
                                <div
                                    onClick={() => {
                                        const linkedinUrl = 'https://in.linkedin.com/in/nivedita-shetty';

                                        if (linkedinUrl) {
                                            window.open(linkedinUrl, '_blank');
                                        } else {
                                            console.error('LinkedIn URL is undefined');
                                        }
                                    }}
                                ><img src={LinkedIn} alt="LinkedIn" /></div>
                            </div>
                            <div className='about-containerSeven-twoBlog-info'>
                                <p>With over 15 years of extensive experience, she has demonstrated a profound understanding of the HR landscape and a proven track record of achieving tangible results.</p>
                            </div>
                            <div className='about-containerSeven-twoBlog-Person'>
                                <div>
                                    <img src={Nivedita} alt="Nivedita" />
                                </div>
                                <div>
                                    <div className='about-containerSeven-twoBlogName'><p>Nivedita Shetty</p></div>
                                    <div className='about-containerSeven-twoBlogPosition'><p>Head HR</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </marquee> */}

                    <div>
                        <hr style={{ marginTop: "80px" }} />
                        <div className='about-containerSeven-Three'>
                            <p>Ready to shape the future with us?</p>
                            <Button ele={buttonPropsCareer} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
