import React from 'react'
import { Helmet } from "react-helmet"
import '../../styles/Services/DataEngg.css'
import section1 from '../../assets/Services/DataEngg/section-1.png'
import { Section2 } from '../../components/Services/common/Section2'
import section2 from '../../assets/Services/DataEngg/section-2.png'
import icon1 from '../../assets/Services/DataEngg/icon-1.png'
import icon2 from '../../assets/Services/DataEngg/icon-2.png'
import icon3 from '../../assets/Services/DataEngg/icon-3.png'
import icon4 from '../../assets/Services/DataEngg/icon-4.png'
import icon5 from '../../assets/Services/DataEngg/icon-5.png'
import gcloud from '../../assets/Services/DataEngg/gcloud.png'
import snowflake from '../../assets/Services/DataEngg/snowflake.png'
import pathway from '../../assets/Services/DataEngg/pathway.png'
import efficiency from '../../assets/Services/DataEngg/efficiency.jpg'
import innovation from '../../assets/Services/DataEngg/innovation.jpg'
import Button from '../../components/Button'
import integration from '../../assets/Services/DataEngg/data-integration.png'
import warehouse from '../../assets/Services/DataEngg/data-warehouse.png'
import pipeline from '../../assets/Services/DataEngg/pipeline.png'
import analytics from '../../assets/Services/DataEngg/analytics.png'
import quote from '../../assets/Services/DataEngg/quote-icon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { CircleDataEngineering } from '../../components/CircleInfo/CircleDataEngineering'

export const DataEngg = () => {
  const handleClick = () => {
    const footerElement = document.getElementById('sec1-prt2');
    const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - 68;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  const quoteBtnProps = {
    onClick: handleClick,
    text: 'Get a Quote',
    backgroundColor: '#ffffff',
    color: '#20303F',
    image: quote,
    border: '2px solid #20303F'
  };

  const getInTouchProps = {
    onClick: handleClick,
    text: 'Get in Touch',
    icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
    backgroundColor: '#20303F',
    color: '#ffffff',
  };

  const section2props = {
    headText: 'Future-Proof Your Business with ',
    boldHeadText: 'Smart Modernization',
    desc: `In today's digital landscape, application modernization is paramount for organizational success. The Unitres team's AI-driven approach offers a transformative path to becoming 'live enterprises'. By seamlessly modernizing legacy applications, businesses can adapt swiftly, ensuring continuity without disruption.`,
    image: section2
  }

  return (
    <>
      <div className='data-engg-container'>
        <Helmet>
          <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
          <meta charSet="utf-8" />
          <title>Service - Data Engineering</title>
          <meta name="keywords" content="
              aws data engineering
              gcp data engineer
              cloud data engineering
              google data engineer
              google certified data engineer
              data engineer google
              google cloud certified data engineer
              google cloud data engineer
              google cloud professional data engineer
              google professional data engineer
              aws data engineer project
              aws data engineering courses
              google certified professional data engineer
              google cloud certified professional data engineer
              gcp professional data engineer
              aws for data engineers
              professional data engineer
              data engineer gcp
              aws cloud data engineer
              aws cloud data engineering
              gcp engineer
              gcp certified data engineer
              google cloud professional data engineer certified
              google data engineer cert
              cloud database engineer
              cloud data engineering for dummies
              data engineer at google
              data engineering with google cloud platform
              aws academy data engineering
              data engineer cloudera
              google cloud database engineer
              professional cloud database engineer
              gcp for data engineer
              google cloud professional data engineer get certified 2022
              professional data engineer google
              data engineering on google cloud platform
              gcp cloud data engineer
              data cloud engineer
              google cloud platform data engineer
              professional data engineer gcp
              data engineer cloud
              data engineering with gcp
              gcp pde
              cloud data engineer aws
              engineer data in google cloud challenge lab
              gcp data scientist
              certified professional data engineer
              learn aws data engineering
              data engineering with google cloud
              professional data engineer google cloud
              gcp professional cloud data engineer
              data engineering on google cloud platform specialization
              data engineer google cloud
              google cloud professional data engineer practice dumps 2022
              data engineering on google cloud
              data engineering using aws
              aws data engineering architecture
              cloud data engineer gcp
              gcp certified professional data engineer
              data engineer in google
              linux academy gcp data engineer
              cloud and data engineering
              google database engineer
              professional data engineer on google cloud platform
              data engineering in gcp
              google certified professional engineer
              official google cloud certified professional data engineer
              cloud data engineer google
              best cloud for data engineering
              engineer data in google cloud
              google cloud platform certified data engineer practice tests
              aws data engineering immersion day
              data engineering google cloud platform
              certified google data engineer
              google certified professional data engineer requirements
              gcp data engineer syllabus
              gcp data engineer practice test
              aws big data engineer
              aws python data engineer
              big data cloud engineer
              big data engineer at google
              big data engineer google
              bigquery cloud google com
              cloud big data engineer
              cloud data engineer deloitte
              cloud data warehouse engineer
              cloud professional data engineer
              data engineering big data and ml on google cloud
              data engineering with google cloud platform epub
              data engineering with python and aws lambda
              download google cloud professional data engineer get certified 2022
              gcp associate data engineer
              gcp data engineer case studies
              gcp data engineer full form
              gcp data engineer professional
              gcp dataengineer
              gcp in data engineer
              gcp professional data engineer
              google big data engineer
              google certified professional data engineer cost
              google cloud associate data engineer
              google cloud landsat
              google cloud platform professional data engineer
              google cloud professional data engineer get certified 2022 download
              google professional cloud data engineer
              https cloud google com bigquery
              linux academy google data engineer
              professional cloud data engineer
              technical solutions engineer big data google cloud
              data analytics
              analytics
              business analytics
              bigdata
              mastering data analytics
              google data analytics cert
              business intelligence analysts
              predictive analytics
              online analytical processing
              predictive data analytics
              knime
              exploratory data analysis
              data analytics platforms
              exploratory data analytics
              big data analytics
              azure synapse
              excel for data analytics
              big data and data analytics
              google data analytics
              ai data analytics
              data analytics ai
              ai and data analytics
              predictive modelling
              data analytics and ai
              financial data analysis
              digital marketing and data analytics
              data analytics in digital marketing
              people analytics
              zoho analytics
              data analytics for beginners
              business data analytics
              data analytics for business
              business and data analytics
              azure synapse analytics
              healthcare analytics
              data analytics companies
              sql for data analysis
              business intelligence and analytics
              sql for data analytics
              business intelligence analytics
              business data analysis
              analytics in hr
              data analysis using sql
              bi & analytics
              business intelligence and business analytics
              sql in data analytics
              data analysis system
              data analysis methodology
              analytical processing
              data analytics online
              advanced analytics
              coursera data analysis
              r data analysis
              data analytics with r
              crm analytics
              best online data analytics programs
              learning data analytics
              google data science
              business intelligence platforms
              bi platforms
              business intelligence and data analytics
              data insights
              data analysis in healthcare
              learning data analysis
              bi data analytics
              bi and data analytics
              data analytics business intelligence
              product analytics
              best data analytics programs
              data science and analytics
              python for data analytics
              data and analytics
              analytics platforms
              data analytics solutions
              data analytics for managers
              data science analytics
              customer data analysis
              exploratory data analysis in data science
              data and analysis
              sas analytics
              cognos analytics
              oracle analytics cloud
              ibm cognos analytics
              healthcare data analytics
              predictive analytics in healthcare
              learning analytics
              procurement analytics
              cloud analytics
              data analytics programs
              coursera google data analytics
              simplilearn data analytics
              retail analytics
              marketing data
              ai analytics
              real time analytics
              streaming analytics
              reporting and analytics
              quantitative data analysis
              analytics reporting
              business analytics and data analytics
              insights and analytics
              insights analytics
              data analytics google
              aws data analytics
              spss data analysis
              salesforce analytics
              hr data analytics
              sas visual analytics
              audit analytics
              marketing data analytics
              data analysis in research
              data science in healthcare
              big data companies
              business intelligence systems
              big data in healthcare
              data analytics aws
              data analytics for marketing
              hr data and analytics
              visual analytics
              data science for marketing
              marketing and data analytics
              accounting and data analytics
              business intelligence app
              big data and healthcare
              python exploratory data analysis
              analytics solution
              research and data analysis
              big data ecommerce
              dashboards google
              aws analytics
              sas data analytics
              einstein analytics
              aws certified data analytics
              netsuite analytics warehouse
              data science companies
              sas data analysis
              data analytics and visualization
              self service analytics
              data analytics roadmap
              operational analytics
              data analysis roadmap
              financial data analytics
              data analytics visualization
              healthcare analysis
              retail business intelligence
              udemy data analytics
              knime analytics
              synapse azure
              sap analytics
              tableau data analytics
              synapse analytics
              real estate data analytics
              data analysis online
              health data analytics
              advanced data analytics
              data analysis using python
              data analytics strategy
              analytics companies
              azure data analytics
              data and analytics strategy
              data analytics and machine learning
              tableau data analysis
              kinesis data analytics
              data analytics using python
              power bi predictive analytics
              business analytics dashboard
              looker business intelligence
              data analysis and reporting
              data analytics machine learning
              data analysis strategy
              segment analytics
              gartner data and analytics
              tableau analytics
              ibm data analytics
              augmented analytics
              gartner data analytics
              iot analytics
              manufacturing analytics
              tableau business intelligence
              ai predictive analytics
              data analytics dashboard
              data analysis and visualization
              azure analytics
              cloud data analytics
              data management and analytics
              retail data analytics
              accounting analytics
              machine learning for data analysis
              data analysis dashboard
              enterprise analytics
              data analytics in retail industry
              ai and analytics
              retail data analysis
              business intelligence data
              analyticslab
              data science and business analytics
              looker analytics
              teradata vantage
              website data analytics
              data mining for business analytics
              export google analytics data
              big data analytics in healthcare
              data analytics power bi
              supply chain data science
              synapse data
              power bi data analysis
              business analytics in healthcare
              web data analytics
              business analytics and business intelligence solutions
              google data analytics coursera
              alteryx cloud
              data analytics coursera
              snowflake analytics
              power bi analytics
              aws certified data analytics specialty
              ibm analytics
              top data analytics companies
              dashboard analytics
              data analysis website
              customer data analytics
              analytics as a service
              looker data visualization
              python analytics
              healthcare analytics companies
              real time data analytics
              workday prism analytics
              supply chain data analytics
              google bigdata
              analytics strategy
              fusion analytics warehouse
              data management and analysis
              big data science
              big data analytics companies
              using r for data analysis
              big data and analytics
              time series data analysis
              ga4 dashboard
              big data processing
              big data business intelligence
              banking analytics
              big data and data science
              big data and business intelligence
              big data data science
              best data science companies
              data analytics using r
              data analytics and reporting
              bigquery omni
              statistical analytics
              rapid miner studio
              research and analytics
              big data in data science
              alteryx data analytics
              python for business analytics
              aws data science
              amazon data analytics
              microsoft data analytics
              data analytics for dummies
              sap data analytics
              healthcare data companies
              healthcare data analytics companies
              sql analytics
              unstructured data analytics
              big data and business analytics
              tableau data science
              data infrastructure and analytics
              geospatial data analysis
              enterprise data analytics
              manufacturing data analytics
              zoho dataprep
              big data in retail
              big data business analytics
              data analytics technology
              adobe data
              big data in business
              big data predictive analytics
              data analysis and data analytics
              big data analytics solutions
              adobe sitecatalyst
              service analytics
              coursera business analytics
              google data analysis
              data analysis for beginners
              marketing analytics dashboard
              hr analytics dashboard
              azure data lake analytics
              applied data analytics
              data lake analytics
              iot data analytics
              synapse data warehouse
              azure synapse pipelines
              datasets for data analysis
              market data analysis
              amazon kinesis data analytics
              data analytics in banking
              basic data analysis
              oracle fusion analytics
              data analytics case study
              clinical analytics
              data analytics industry
              social data analytics
              clickstream analysis
              data and insights
              dbt analytics
              predictive data
              predictive business analytics
              synapse pipelines
              big data industry
              axtria company
              gartner analytics
              gartner data
              alteryx designer cloud
              azure synapse pricing
              esg analytics
              salesforce analytics cloud
              data science and data analytics
              azure data lakehouse
              saas data analytics
              data analytics for accounting
              aws for data science
              data analytics firms
              oracle data visualization
              aws iot analytics
              business analytics companies
              self service business intelligence
              agile analytics
              azure big data
              mongodb analytics
              data analysis in quantitative research
              healthcare analytics solutions
              microsoft excel data analysis
              top data science companies
              kinesis analytics
              automated data analysis
              sigmoid analytics
              data analysis case study
              azure streaming
              data driven analytics
              data analysis in research methodology
              data mining and analytics
              big data research
              big data systems
              data analysis and data science
              it analytics
              data science and data analysis
              big data analytics in iot
              experian ascend
              actionable analytics
              google cloud analytics
              alteryx automation
              qlik analytics
              mode analytics sql
              data analytics for product managers
              smart data analytics
              microsoft azure synapse
              data analytics as a service
              azure synapse studio
              digital experience analytics
              hr dashboard power bi
              best data analytics companies
              data pipelines pocket reference
              data science in manufacturing
              databricks serverless sql
              aws kinesis data analytics
              visual data analysis
              clinical data analysis
              top analytics companies
              clickstream analytics
              health it analytics
              cohort analysis tableau
              data to insights
              meta data analysis
              smart analytics
              data driven analysis
              data analytics insights
              analytics service
              analytics studio
              big data mining and analytics
              ga4 bigquery schema
              companies using big data
              salesforce acquires tableau
              netflix data analytics
              ga4 data streams
              alteryx analytics
              study data analytics
              coursera data analytics google
              data analysis app
              aws data analytics specialty
              starburst analytics
              cpg data insights
              cpg analytics
              google analytics data studio
              data quality analysis
              salesforce data analytics
              deloitte data analytics
              synapse analytics azure
              snowflake data analytics
              sisense periscope
              pwc data analytics
              splunk analytics
              aws certified big data specialty
              ga4 to bigquery
              data science and big data analytics
              ibm predictive analytics
              databricks sql serverless
              streaming data analytics
              data analytics market
              synapse sql
              das c01
              retail analytics solutions
              data science in banking
              data analytics and insights
              idea data analysis
              predictive machine learning
              sql analysis
              text data analysis
              big data in manufacturing
              data analysis and decision making
              hadoop analytics
              big data accounting
              big data in banking
              statistical analysis and data mining
              real time big data analytics
              spark analytics
              synapse spark
              netflix analytics
              predictive data mining
              regression analysis in data mining
              sisense bi
              google analytics to bigquery
              google data analytics professional
              kpmg data analytics
              data analytics service
              acl data analytics
              oracle data analytics
              data science business analytics
              microsoft azure synapse analytics
              retail analytics companies
              self service data analytics
              azure synapse workspace
              business intelligence guidebook from data integration to analytics
              accenture analytics
              visual analytics with tableau
              energy data analytics
              web3 analytics
              data insights company
              azure synapse data warehouse
              microsoft power bi service
              analytics roadmap
              sap data science
              intelligent data analysis
              analytics in healthcare industry
              idea analytics
              data science in digital marketing
              data mining and predictive analytics
              predictive data modeling
              analysis analytics
              analysis and analytics
              data analytics solution companies
              gcp analytics
              google cloud data analytics
              data analysis for management
              azure synapse analytics documentation
              human analytics
              data analysis using spss
              geospatial data analytics
              google analytics export raw data
              business insights and analytics
              search analytics for sheets
              data science and marketing analytics
              azure synapse sql
              mobile data analytics
              orange data analysis
              google analytics export
              aws kinesis analytics
              use of data analytics
              geo analytics
              r for marketing research and analytics
              digital data analytics
              google analytics sampling
              ga4 bigquery export
              hands on data analysis with pandas
              knime pricing
              intelligent data analysis in big data
              time series analytics
              ga4 schema
              modern data analytics
              kql azure
              synapse dedicated sql pool
              blockchain data science
              data mining in data science
              diagnostic data analysis
              gcp data analytics
              athena analytics
              databricks sql analytics
              periscope analytics
              mcse data management and analytics
              mckinsey data analytics
              google analytics studio
              real time big data
              mixpanel gdpr"
          />
        </Helmet>

        <section className='data-engg-section-1'>
          <div className='data-engg-section-1-left'>
            <p>Data Engineering & Analytics</p>
            <p>Accelerate Decision - Making With <span>Data Engineering</span> </p>
          </div>
          <div className='data-engg-section-1-right'>
            <img src={section1} alt="" />
          </div>
        </section>

        <section>
          <Section2 e={section2props} />
        </section>

        <section className='data-engg-section-3'>
          <div className='data-engg-section-3-left'>
            <p>Why do Actionable </p>
            <p>Insights Matter</p>
            <p>Integrate your enterprise's operations, workforce, and valuable insights through the power of intelligent process automation (IPA) to achieve remarkable efficiency improvements while keeping costs in check.  </p>
            <p>From spotting emerging trends to predicting customer behavior, our data engineering solutions provide actionable insights, propelling businesses toward success in the ever-evolving tech industry.</p>
          </div>
          <div className='data-engg-section-3-right'>
            <div className='data-engg-section-3-right-1'>
              <div>
                <p> Customer Attribution</p>
                <p>Identify the impact of each touchpoint on customer behavior to optimize marketing strategies.</p>
              </div>
              <div>
                <p>Supply Chain</p>
                <p>Streamlines operations and reduces costs by improving visibility and efficiency across the supply chain.</p>
              </div>
            </div>
            <div className='data-engg-section-3-right-2'>
              <div>
                <p>Corporate Performance Management</p>
                <p>Enhance decision-making by aligning strategies and objectives with performance metrics.</p>
              </div>
            </div>
          </div>
        </section>

        <section className='data-engg-section-4'>
          <div className='data-engg-section-4-container'>
            <div className='data-engg-section-4-top'>
              <p>How Intelligence Solutions</p>
              <p>Leverage the Power of Your Data</p>
            </div>
            <div className='data-engg-section-4-bottom'>
              <div className='data-engg-section-4-bottom-1'>
                <div>
                  <img src={icon1} alt="Artificial Intelligence" />
                  <p className='section-4-bold-head'>Artificial Intelligence</p>
                  <p className='section-4-desc'>Offers a crucial path for companies to keep pace.</p>
                </div>
                <div>
                  <img src={icon2} alt="Intelligent Insights" />
                  <p className='section-4-bold-head'>Intelligent Insights</p>
                  <p className='section-4-desc'>Accelerating feature releases within stringent timelines and cost-effective budgets.</p>
                </div>
                <div>
                  <img src={icon3} alt="Platform Modernization" />
                  <p className='section-4-bold-head'>Platform Modernization</p>
                  <p className='section-4-desc'>Revolutionize document management with Application Modernization</p>
                </div>
              </div>
              <div className='data-engg-section-4-bottom-2'>
                <div>
                  <img src={icon4} alt="Data Fabric" />
                  <p className='section-4-bold-head'>Data Fabric</p>
                  <p className='section-4-desc'>Streamline claims processes in the digital age with our Application Modernization solutions</p>
                </div>
                <div>
                  <img src={icon5} alt="Strategy-and-Enablement" />
                  <p className='section-4-bold-head'>Strategy-and-Enablement</p>
                  <p className='section-4-desc'>Accelerating feature releases within stringent timelines and cost-effective budgets.</p>
                </div>
              </div>
            </div>
          </div>

        </section>

        <section className='data-engg-section-5'>
          <div>
            <p>Key Partnerships with largest
              <br />
              business companies out there.</p>
          </div>
          <div>
            <img src={gcloud} alt="Google Cloud" />
            <img src={snowflake} alt="Snowflake" />
          </div>
        </section>

        <section className='data-engg-section-6'>
          <div className='section-6-content'>
            <div>
              <p>Data is the key to profitable growth</p>
              <p>Unlock Profitable Growth with the Power of Data : Transform Comprehensive Insights into Strategic Impact for Sustainable Success</p>
            </div>
            <div>
              <Button ele={getInTouchProps} />
            </div>
          </div>
        </section>

        <section className='data-engg-section-7'>
          <div className='section-7-top'>
            <p>How We Leverage</p>
            <strong>Your Data Potential</strong>
          </div>

          <div className='section-7-bg'>
            <div className='section-7-bottom'>
              <div>
                <img src={pathway} alt="Pathway" />
                <p className='section-7-title'>PATHWAY</p>
                <p className='section-7-head'>End-to-End Data Engineering Services</p>
                <p className='section-7-desc'>Offers a crucial path for companies to keep pace.</p>
              </div>
              <div>
                <img src={efficiency} alt="Efficiency" />
                <p className='section-7-title'>EFFICIENCY</p>
                <p className='section-7-head'>Unmatched Expertise</p>
                <p className='section-7-desc'>Accelerating feature releases within stringent timelines and cost-effective budgets.</p>
              </div>
              <div>
                <img src={innovation} alt="Innovation" />
                <p className='section-7-title'>INNOVATION</p>
                <p className='section-7-head'>Proven Accelators for Faster time to value</p>
                <p className='section-7-desc'>Revolutionize document management with Application Modernization</p>
              </div>
            </div>
          </div>
        </section>

        <section className='data-engg-section-8' >
          <div className='data-engg-section-8-top'>
            <p>From Secure Foundations</p>
            <p><strong>to Actionable Data Insights</strong></p>
            <p>Take a Look at Our 5 step Data Engineering Process</p>
          </div>
          <CircleDataEngineering />
        </section>

        <section className='data-engg-section-9'>
          <div className='section-9-top'>
            <p>unitres tech services</p>
            <strong>Achieve Efficiency with Data Engineering Solutions</strong>
          </div>

          <div className='section-9-bottom'>
            <div className='data-engg-section-9-bottom-left'>
              <div>
                <img src={integration} alt="Data-Integration" />
                <p className='section-9-bottom-head'>Data Integration and ETL (Extract, Transform, Load)</p>
                <ul>
                  <li><strong>Extracting data: </strong>Pulling data from various sources, including databases, cloud storage, APIs, and flat files.</li>
                  <li><strong>Transforming data: </strong>Cleaning, normalizing, and restructuring the data into a suitable format that aligns with business needs.</li>
                  <li><strong>Loading data: </strong>Transferring the transformed data into a centralized system or data warehouse for easy access and analysis.</li>
                </ul>
              </div>

              <div>
                <img src={warehouse} alt="Data-Warehouse" />
                <p className='section-9-bottom-head'>Data Warehousing</p>
                <ul>
                  <li><strong>Designing data warehouses: </strong>Creating robust architectures to store structured data efficiently.</li>
                  <li><strong>Implementing solutions: </strong>Setting up scalable data warehousing solutions that can handle large volumes of data.</li>
                  <li><strong>Managing data warehouses: </strong>Maintaining and optimizing data storage, ensuring data integrity and performance.</li>
                </ul>
              </div>

              <div>
                <img src={pipeline} alt="Data-Pipeline" />
                <p className='section-9-bottom-head'>Data Pipeline Development</p>
                <ul>
                  <li><strong>Creating automated pipelines: </strong>Developing systems to automate the flow of data from sources to destinations, ensuring data is processed continuously and accurately.</li>
                  <li><strong>Ensuring data quality: </strong>Implementing checks and balances to maintain data accuracy, completeness, and consistency throughout the pipeline.</li>
                </ul>
              </div>

              <div>
                <img src={analytics} alt="Data-Analytics" />
                <p className='section-9-bottom-head'>Analytics and BI (Business Intelligence) Solutions</p>
                <ul>
                  <li><strong>Developing analytics platforms: </strong>Building platforms that support advanced data analysis, including statistical analysis, machine learning, and predictive modeling.</li>
                  <li><strong>Enabling data-driven insights: </strong>Providing businesses with the tools and insights needed to make informed decisions based on data.</li>
                </ul>
              </div>
            </div>
            <div className='data-engg-section-9-bottom-right'>
              <div>
                <p>Effective data engineering needs proper execution </p>
                <strong>—and we know how.</strong>
                <Button ele={quoteBtnProps} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
