import React, { useState } from 'react'
import '../../styles/Homepage/Section8.css'
import { frontend } from '../../data/techstacks/frontend';
import { backend } from '../../data/techstacks/backend';
import { cloud } from '../../data/techstacks/cloud';
import { data } from '../../data/techstacks/data';
import { quality } from '../../data/techstacks/quality';


export const Section8 = () => {

    const [techstack, setTechstack] = useState({
        frontend: true,
        backend: false,
        quality: false,
        cloud: false,
        data: false
    })

    const handleToggle = (type) => {
        setTechstack(prevState => ({
            ...prevState, frontend: type === 'frontend',
            backend: type === 'backend',
            quality: type === 'quality',
            cloud: type === 'cloud',
            data: type === 'data'
        }));
    }

    return (
        <div className='techstack-model'>
            <div>
                <p className='techstack-heading'>Technology <span className='techstack-heading-bold'>Working Stack</span> </p>
                <p className='techstack-desc'>Unlock Your Software’s Full Potential with Our Comprehensive Technology Stack</p>
            </div>

            <div className='techstack-options'>
                <button onClick={() => handleToggle('frontend')}
                    disabled={techstack.frontend === true}
                    style={{
                        backgroundColor: techstack.frontend === true ? 'black' : 'white',
                        color: techstack.frontend === true ? 'white' : 'black'
                    }}
                    className='techstack-button'
                >Front-end</button>
                <button onClick={() => handleToggle('backend')}
                    disabled={techstack.backend === true}
                    style={{
                        backgroundColor: techstack.backend === true ? 'black' : 'white',
                        color: techstack.backend === true ? 'white' : 'black'
                    }}
                >Back-end</button>
                <button onClick={() => handleToggle('cloud')}
                    disabled={techstack.cloud === true}
                    style={{
                        backgroundColor: techstack.cloud === true ? 'black' : 'white',
                        color: techstack.cloud === true ? 'white' : 'black'
                    }}
                >Cloud</button>
                <button onClick={() => handleToggle('data')}
                    disabled={techstack.data === true}
                    style={{
                        backgroundColor: techstack.data === true ? 'black' : 'white',
                        color: techstack.data === true ? 'white' : 'black'
                    }}
                >Data</button>
                <button onClick={() => handleToggle('quality')}
                    disabled={techstack.quality === true}
                    style={{
                        backgroundColor: techstack.quality === true ? 'black' : 'white',
                        color: techstack.quality === true ? 'white' : 'black'
                    }}
                >Quality Assurance</button>
            </div>

            <div className='techstack-container'>
                {
                    techstack.frontend && frontend.map((e) => {
                        return <div key={e.id}>
                            <img src={e.logo} alt={e.name} loading='lazy' />
                            <p>{e.name}</p>
                        </div>
                    })
                }
                {
                    techstack.backend && backend.map((e) => {
                        return <div key={e.id}>
                            <img src={e.logo} alt={e.name} />
                            <p>{e.name}</p>
                        </div>
                    })
                }
                {
                    techstack.quality && quality.map((e) => {
                        return <div key={e.id}>
                            <img src={e.logo} alt={e.name} />
                            <p>{e.name}</p>
                        </div>
                    })
                }
                {
                    techstack.cloud && cloud.map((e) => {
                        return <div key={e.id}>
                            <img src={e.logo} alt={e.name} />
                            <p>{e.name}</p>
                        </div>
                    })
                }
                {
                    techstack.data && data.map((e) => {
                        return <div key={e.id}>
                            <img src={e.logo} alt={e.name} />
                            <p>{e.name}</p>
                        </div>
                    })
                }
            </div>
        </div>
    )
}
