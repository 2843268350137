import React from 'react'
import '../../styles/Homepage/Section5.css'
import model1 from '../../assets/Homepage/model-data.png'
import model2 from '../../assets/Homepage/model-elite.png'
import model3 from '../../assets/Homepage/model-design.png'
import model4 from '../../assets/Homepage/model-product.png'
import motivate from '../../assets/Homepage/model-motivate.png'
import strategy from '../../assets/Homepage/model-strategy.png'
import team from '../../assets/Homepage/model-team-building.png'
import launch from '../../assets/Homepage/model-product-launch.png'

export const Section5 = () => {
    return (
        <div className='section-5-container'>
            <div className='section-5-top'>
                <p className='section-5-line1'>Unitres Operating Model</p>
                <p className='section-5-line2'>How we Help your ideas grow </p>
                <p className='section-5-line3'>& transform into a conversion powerhouse optimising every interaction to turn visitors into loyal customers.</p>
            </div>

            <div className='section-5-bottom'>
                <div>
                    <div className='section-5-bottom-content'>
                        <div className='section-5-head'>
                            <img src={model1} alt="data" />
                            <p>1. Data</p>
                        </div>
                        <p className='section-5-desc'>Transform data into actionable insights, empowering intelligent and agile operations.</p>
                    </div>
                    <img src={strategy} alt="strategy" />
                </div>

                <hr />

                <div>
                    <div className='section-5-bottom-content'>
                        <div className='section-5-head'>
                            <img src={model2} alt="elite" />
                            <p className='section-5-head'>2. Elite</p>
                        </div>
                        <p className='section-5-desc'>Embrace a digital, cloud, and AI-centric architecture to stay ahead with rapid technological innovations.</p>
                    </div>
                    <img src={team} alt="team-building" />
                </div>

                <hr />

                <div>
                    <div className='section-5-bottom-content'>
                        <div className='section-5-head'>
                            <img src={model3} alt="design" />
                            <p className='section-5-head'>3. Design</p>
                        </div>
                        <p className='section-5-desc'>Craft dynamic, on-demand, user-friendly, and customized digital experiences.</p>
                    </div>
                    <img src={motivate} alt="motivate-someone" />
                </div>

                <hr />

                <div>
                    <div className='section-5-bottom-content'>
                        <div className='section-5-head'>
                            <img src={model4} alt="product" />
                            <p className='section-5-head'>4. Product</p>
                        </div>
                        <p className='section-5-desc'>Shift to a customer-focused, product-oriented framework powered by a robust platform ecosystem.</p>
                    </div>
                    <img src={launch} alt="product-launch" />
                </div>
            </div>
        </div>
    )
}
