import './App.css';
import ReactGA from 'react-ga';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { AllRoutes } from "./routes/AllRoutes";
import { AllRoutes2 } from "./routes/AllRoutes2"
import { Blog } from './components/Blog';
import { UniTradeHomepage } from './UniAlgoTrade/UniTradeHomepage';
import { ApiNavbar2 } from './ApiGateway/components/ApiNavbar2';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { Notify } from './ApiGateway/components/Common/Notify';

function App() {
  const currentUrl = window.location.pathname;
  const firstPart = currentUrl.split('/')[1];
  const location = useLocation();

  const theme = createTheme({
    typography: {
      fontFamily: 'Plus Jakarta Sans, Arial, sans-serif',
    },
  });

  const TRACKING_ID = "G-60WCQ9S9LW";

  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  // Send pageview event when location changes
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  if (firstPart === "UniAlgoTrade") {
    return (
      <div>
        <UniTradeHomepage />
      </div>
    )
  }

  if (firstPart === "api-gateway") {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='App'>
          <ApiNavbar2 />
          <AllRoutes2 />
          <Notify />
        </div>
      </ThemeProvider>
    )
  }

  return (
    <div className="App">
      <Navbar />
      <div id="sec1-prt3"> </div>
      <AllRoutes />
      <Blog />
      <Footer />
    </div>
  );
}

export default App;