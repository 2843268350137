import react from '../../assets/Techstacks/Frontend/react.png'
import vue from '../../assets/Techstacks/Frontend/vue.png'
import flutter from '../../assets/Techstacks/Frontend/flutter.png'
import angular from '../../assets/Techstacks/Frontend/angular.png'
import bootstrap from '../../assets/Techstacks/Frontend/bootstrap.png'
import css from '../../assets/Techstacks/Frontend/css3.png'
import jquery from '../../assets/Techstacks/Frontend/jquery.png'
import ember from '../../assets/Techstacks/Frontend/ember.png'
import nextjs from '../../assets/Techstacks/Frontend/nextJs.png'

export const frontend = [
    {
        id: 1,
        name: 'React.js',
        logo: react
    },
    {
        id: 2,
        name: 'Vue.js',
        logo: vue
    },
    {
        id: 3,
        name: 'Flutter',
        logo: flutter
    },
    {
        id: 4,
        name: 'Angular.js',
        logo: angular
    },
    {
        id: 5,
        name: 'Bootstrap',
        logo: bootstrap
    },
    {
        id: 6,
        name: 'CSS3',
        logo: css
    },
    {
        id: 7,
        name: 'React Native',
        logo: react
    },
    {
        id: 8,
        name: 'Jquery',
        logo: jquery
    },
    {
        id: 9,
        name: 'Ember.js',
        logo: ember
    },
    {
        id: 10,
        name: 'Next.js',
        logo: nextjs
    }
]