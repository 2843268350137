import { Box, Typography } from '@mui/material'
import Eye from '../assets/Features/Eye.png'
import EyeLeft from '../assets/Features/EyeLeft.png';
import EyeRight from '../assets/Features/EyeRight.png';
import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet"
import "../Styles/Features.css"
import upArrow from "../assets/Features/upArrow.png"
import downArrow from "../assets/Features/downArrow.png"
import RightSign from "../assets/Features/RightSign.png"
import CircleBlue from "../assets/Features/CircleBlue.png"
import CircleBlueCorn from "../assets/Features/CircleBlueCorn.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { data } from "../Data/Features"
import LaunchingSoon from "../assets/Features/LaunchingSoon.png"
import MiniComingsoon from "../assets/Features/MiniComingsoon.png"
import {Section5} from "../components/Homepage/Section5"

export const Feature = () => {

    const [openSections, setOpenSections] = useState({ 0: true });
    const [eyeImage, setEyeImage] = useState(Eye);

    const toggleSection = (index) => {
        setOpenSections(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    useEffect(() => {
        const images = [Eye, EyeLeft, EyeRight];
        let index = 0;

        const interval = setInterval(() => {
            index = (index + 1) % images.length;
            setEyeImage(images[index]);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>API Gateway - Features</title>
            </Helmet>

            <Box sx={{
                width: '100%',
                minHeight: '100%',
                m: 'auto',
                pt: '80px',
                background: "rgba(0, 11, 28, 1)"
            }}>
                {/* Section 1 */}
                <Box sx={{
                    width: {
                        xs: '100%',
                        sm: '100%',
                        md: '80%',
                        lg: '80%'
                    },
                    m: "auto",
                    mt:"40px"
                }}>

                    <Box sx={{
                        display: "flex",
                        flexDirection: {
                            xs: 'column',
                            sm: 'column',
                            md: 'row',
                            lg: 'row'
                        },
                        justifyContent: "space-between"
                    }}>
                        <Box sx={{
                            width: {
                                xs: '100%',
                                sm: '90%',
                                md: '50%',
                                lg: '50%'
                            },
                            display: "flex", justifyContent: "center", alignItems: "center"
                        }}>
                            <Typography sx={
                                {
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: {
                                        xs: '45px',
                                        sm: '45px',
                                        md: '80px',
                                        lg: '80px'
                                    },
                                    fontWeight: "600",
                                    lineHeight: {
                                        xs: '40px',
                                        sm: '40px',
                                        md: '72px',
                                        lg: '72px'
                                    },
                                    letterSpacing: "-0.03em",
                                    background: "linear-gradient(93.37deg, #EEEEEE 70.32%, #000000 100.52%)",
                                    backgroundClip: "text",
                                    color: "transparent",
                                    textAlign: {
                                        xs: 'center',
                                        sm: "center",
                                        md: 'left',
                                    }
                                }
                            }>
                                Our product is launching soon!
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",

                        }}>
                            <img src={eyeImage} alt="Eye" className='eyeimg' />
                        </Box>
                    </Box>

                    <Box sx={{
                        fontSize: {
                            xs: '20px',
                            sm: '20px',
                            md: '30px',
                            lg: '36px'
                        },
                        fontWeight: 300,
                        lineHeight: {
                            xs: '24px',
                            sm: '24px',
                            md: '43.2px',
                            lg: '43.2px'
                        },
                        textAlign: {
                            xs: 'center',
                            sm: 'center',
                            md: 'right',
                            lg: 'right'
                        },
                        width: {
                            xs: '95%',
                            sm: '95%',
                            md: '90%',
                            lg: '90%'
                        },
                        m: "auto",
                        marginTop: {
                            xs: '50px',
                            sm: '50px',
                            md: '100px',
                            lg: '120px'
                        },
                        background: "rgba(255, 255, 255, 0.7)",
                        backgroundClip: "text",
                        color: "transparent",
                    }}>
                        <p>Our ground-breaking API Gateway is launching in just <span className='SpanSectionOne' style={{color:"white"}}>8 months</span>, packed with the <span className='SpanSectionOne' style={{color:"white"}}>top 15 features</span> that will <span className='SpanSectionOne' style={{color:"white"}}>redefine how you manage security, performance, and scalability.</span> </p>
                    </Box>
                </Box>


                {/* section 2 */}

                <Box sx={{
                    marginTop: {
                        xs: '50px',
                        sm: '50px',
                        md: '200px',
                        lg: '200px'
                    },
                    ml:"1px",
                    mr:"1px",
                    // background: "rgba(0, 11, 28, 1)",
                    color: "white",
                    background: "radial-gradient(50% 50% at 50% 50%, rgba(21, 108, 254, 0.53) 30.1%, rgba(0, 11, 28, 0) 100%)"
                }}>
                    <table border="1" className='sectionTwoTable'>
                        <thead>
                            <tr className='TableHeadingTitle'>
                                <th>Production Features</th>
                                <th>Product Roadmap</th>
                                {/* <th>Product Feature Priority</th> */}
                                <th>Development Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((section, index) => (
                                <React.Fragment key={index}>
                                    <tr onClick={() => toggleSection(index)} className='TabledataHeading'>
                                        <th colSpan="2" style={{ cursor: 'pointer' }}>
                                            {section.feature}
                                        </th>

                                        <th colSpan="1" style={{ cursor: 'pointer', textAlign: "right", paddingRight: "20px" }}>
                                            <img src={openSections[index] ? upArrow : downArrow} alt="arrow" />
                                        </th>
                                    </tr>
                                    {openSections[index] && section.details.map((detail, detailIndex) => (
                                        <tr key={detailIndex} className='Tabledata'>
                                            <td>{detail.name}</td>
                                            {/* <td><img src={detail.mvp} alt="right sign" /></td> */}
                                            <td>
                                                {detail.mvp ? <img src={RightSign} alt="right sign" />
                                                    :
                                                    <FontAwesomeIcon icon={faCircleXmark} size="xl" style={{ color: "#156cfe", }} />
                                                }
                                            </td>
                                            {/* <td>{detail.priority}</td> */}
                                            <td style={{ display: "flex", gap: "10px", border: "rgba(0, 11, 28, 1)" }}>
                                                <div className='FeatureCircle'>
                                                    {Array(detail.develop.blue).fill(<img src={CircleBlue} alt="blue circle" style={{ margin: '0 2px' }} />)}
                                                    {Array(detail.develop.nonblue).fill(<img src={CircleBlueCorn} alt="non-blue circle" style={{ margin: '0 2px' }} />)}
                                                </div>
                                                <div>
                                                    {detail.develop.status}
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </Box>


                {/* Section 3 */}
                <Box
                    sx={{
                        height: "459.05px",
                        background: "rgba(0, 11, 28, 1)",
                    }}
                >
                    <div id='Featurebigscreen'><img src={LaunchingSoon} alt="LaunchingSoon" className='FeaturesSectionThree' /></div>
                    <div id='Featuresmallscreen'><img src={MiniComingsoon} alt="MiniComingsoon" className='FeaturesSectionThree' /></div>
                </Box>

                {/* Section 4 */}
                <Box>
                    <Section5/>
                </Box>
                
            </Box>
        </>
    )
}
