import google from '../../assets/Techstacks/Cloud/google-cloud.png'
import aws from '../../assets/Techstacks/Cloud/aws.png'
import azure from '../../assets/Techstacks/Cloud/azure.png'
import oracle from '../../assets/Techstacks/Cloud/oracle.png'
import cloudflare from '../../assets/Techstacks/Cloud/cloudflare.png'
import ocean from '../../assets/Techstacks/Cloud/digital-ocean.png'

export const cloud = [
    {
        id: 1,
        name: 'Google Cloud',
        logo: google
    },
    {
        id: 2,
        name: 'AWS',
        logo: aws
    },
    {
        id: 3,
        name: 'Micosoft Azure',
        logo: azure
    },
    {
        id: 4,
        name: 'Oracle Cloud',
        logo: oracle
    },
    {
        id: 5,
        name: 'Cloud Flare',
        logo: cloudflare
    },
    {
        id: 6,
        name: 'Digital Ocean',
        logo: ocean
    },
]