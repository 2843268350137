// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  .CareerContainertwo{
    color:rgba(0, 0, 0, 0.5);
  }
.careerContainerOne h6{
  margin-bottom: 0px;
}

.green-text {
  color: green;
}
.right-arrow {
  float: right;
}
.Careerdeatils{
  text-align:right;
  margin:-20px ;
}

details summary {
  list-style: none;
}

details summary::before {
  color: green;
  content: '+';
  font-size: 25px;
}

details[open] summary::before {
  content: '- ';
}

@media (max-width: 1281px){
  body{
    font-size: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Career/Career2.css"],"names":[],"mappings":"EAAE;IACE,wBAAwB;EAC1B;AACF;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":["  .CareerContainertwo{\n    color:rgba(0, 0, 0, 0.5);\n  }\n.careerContainerOne h6{\n  margin-bottom: 0px;\n}\n\n.green-text {\n  color: green;\n}\n.right-arrow {\n  float: right;\n}\n.Careerdeatils{\n  text-align:right;\n  margin:-20px ;\n}\n\ndetails summary {\n  list-style: none;\n}\n\ndetails summary::before {\n  color: green;\n  content: '+';\n  font-size: 25px;\n}\n\ndetails[open] summary::before {\n  content: '- ';\n}\n\n@media (max-width: 1281px){\n  body{\n    font-size: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
