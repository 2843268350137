import React from 'react'
import { Helmet } from "react-helmet"
import { Section1 } from '../components/Homepage/Section1'
import { Section2 } from '../components/Homepage/Section2'
import { Section3 } from '../components/Homepage/Section3'
import { Section4 } from '../components/Homepage/Section4'
import { Section5 } from '../components/Homepage/Section5'
import { Section6 } from '../components/Homepage/Section6'
import { Section7 } from '../components/Homepage/Section7'
import { Section8 } from '../components/Homepage/Section8'
import { Section9 } from '../components/Homepage/Section9'

export const Homepage = () => {
    return (
        <div className='homepage-container'>
            <Helmet>
                <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
                <meta charSet="utf-8" />
                <title>UnitresTech</title>
            </Helmet>

            <Section1 />
            <Section2 />
            <Section3 />
            <Section4 />
            <Section5 />
            <Section6 />
            <Section7 />
            <Section8 />
            <Section9 />
        </div>
    )
}
