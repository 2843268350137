import React from 'react'
import '../../styles/Homepage/Section1.css'
import usymbol from '../../assets/Homepage/u-symbol.png'
import gcloud from '../../assets/Homepage/gcloud.png'
import snowflake from '../../assets/Homepage/snowflake.png'
import Button from '../Button'
import quote from '../../assets/Homepage/quote-icon.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

export const Section1 = () => {
    const navigate = useNavigate();
    const handleService = () => {
        navigate('/service/application-modernization');
    };

    const handleQuote = () => {
        const footerElement = document.getElementById('sec1-prt2');
        const elementPosition = footerElement.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 68;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    };

    const serviceBtnProps = {
        onClick: handleService,
        text: 'Services',
        icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
        backgroundColor: '#20303F',
        color: '#ffffff',
        border: '2px solid #20303F'
    };

    const quoteBtnProps = {
        onClick: handleQuote,
        text: 'Get a Quote',
        backgroundColor: '#ffffff',
        color: '#20303F',
        image: quote,
        border: '2px solid #20303F'
    };

    return (
        <div className='section-1'>
            <div className='section-1-left'>
                <div className='section-1-heading'>
                    <p>Think <span className='bold-heading'>Tech</span></p>
                    <p>Think <span className='bold-heading'>Unitres Tech</span></p>
                </div>
                <p className='section-1-desc'>Driving Technology Driven Intelligent Outcomes </p>
                <div className='section-1-buttons'>
                    <Button ele={serviceBtnProps} />
                    <Button ele={quoteBtnProps} />
                </div>

                <div>
                    <p className='section-1-bottom'>Top-Tier Industry Partners</p>
                    <div className='partners'>
                        <img src={gcloud} alt='Google Cloud' />
                        <img src={snowflake} alt='snowflake' />
                    </div>
                </div>
            </div>
            <div className='section-1-right'>
                <img src={usymbol} alt='Unitrestech' />
            </div>
        </div>
    )
}
