import React, { useState, useEffect } from 'react'
import { Helmet } from "react-helmet";
import '../styles/Career/Career.css'
import humanRight from '../assets/Career/humanRight.png'
import GroupImg from '../assets/Career/GroupImg.png'
import Icon from '../assets/Career/Icon.png'
import iT from '../assets/Career/iT.png'
import IntelligenceIMG from '../assets/Career/IntelligenceIMG.png'
import logo from '../assets/Homepage/logo-transparent.png'
import It2 from '../assets/Career/It2.png'
import { Career } from '../components/Career/Career'
import { Career2 } from '../components/Career/Career2'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom'

export default function Careers() {

  const navigate = useNavigate();

  const [isTablet, setIsTablet] = useState(false);

  const handleNavigation = () => {
    navigate('/service/application-modernization')
  };

  const buttonPropsabout = {
    onClick: handleNavigation,
    text: 'About us',
    icon: <FontAwesomeIcon icon={faArrowRight} transform={{ rotate: -45 }} />,
    backgroundColour: '#20303F',
    color: '#ffffff',
    width: "500px",
    border: "none"
  };

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth > 1350);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/45461471.js"></script>
        <meta charSet="utf-8" />
        <title>UnitresTech - Careers </title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>

      <div className='Career-container'>
        {/* 1 */}
        <div className='Career-containerOne'>
          <div className='Career-container-one'>
            <p className='Career-container-oneTitle'>Careers at Unitres Tech</p>
            <p className='Career-container-oneTitle2'>Job Postings <br /> <strong>& Hiring</strong></p>
            <p className='Career-container-oneInfo'>Forward-thinking strategists shaping the future with innovative, impactful solutions.</p>
          </div>

          <div className='career-container-two'>
            <img src={humanRight} alt="humanRight" />
          </div>
        </div>

        {/* 2 */}
        <div>
          <div className='Career-containerTwo'>
            <img src={IntelligenceIMG} alt='HadnShake' className='HadnShakeimg' />
            <div className='Career-containerTwo-one'>
              <img src={logo} alt='logo' className='logoT' />
              <p className='Career-containerTwo-line2'>Grow Your Job Potential With <span className='homepage-content-one-line3'>The Best In IT Excellence.</span>
              </p>
              <p className='Career-containerTwo-line4'>We trust and celebrate the greatness in people and value human relationships. Level up your career with cutting-edge IT expertise and maximise your potential.</p>
              <div className='Career-containerTwo-bottom' onClick={() => navigate('/about')}>

                <Button ele={buttonPropsabout} />
                <img src={It2} alt='It2' className='It2' />
              </div>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className='Career-containerThree'>
          <div className='Career-container-Three'>
            <p className='Career-container-Three-Line1'>At UNITRES Tech,<br /> Our Goal Is To Cultivate A</p>
            <p className='Career-container-Three-Line2'>
              <strong > Dynamic Network Of Skilled Professionals,</strong>
              <strong className='Career-container-Three-subLine2'>
                Blending Expertise With Capabilities.
              </strong>
            </p>
            <p className='Career-container-Three-Line3'>
              At Unitres Tech, we're always on the lookout for talented individuals like you to join our team. Explore our current job openings and discover opportunities to grow, innovate, and make a meaningful impact.
            </p>
          </div>
          <div className='Career-container-Three-two'>
            <img src={GroupImg} alt="GroupImg" />
          </div>
        </div>

        {/* 4 */}
        <div className='Career-containerFourOne'>
          <img src={Icon} alt="Icon" /> <span>WHY UNITRES TECH</span>
          <hr />
        </div>
        <div className='Career-containerFour'>
          <div className='Career-container-Four'>
            <div className='Career-container-FourLine1'>
              <p>Work culture <span> @Unitres Tech </span></p>
            </div>
          </div>

          <div className='Career-containerFourTwo'>
            {/* div1 */}
            <div className='Career-containerFourTwo-One'>
              <div className='Career-containerFourTwo-1'>
                {/* <img src={Flag} alt="Flag" /> */}
                <div className='Career-containerFourTwo-content'>
                  <p className='Career-containerFourTwo-1-Line1'>Growth</p>
                  <p className='Career-containerFourTwo-1-Line2'>Opportunities to Grow</p>
                  <p className='Career-containerFourTwo-1-Line3'>Whether you're a seasoned pro or just starting, you'll face new challenges, expand your skills, and advance your career with our continuous learning culture.</p>
                </div>
              </div>
              <div className='Career-containerFourTwo-2'>
                {/* <img src={Recognition} alt="Recognition" /> */}
                <div className='Career-containerFourTwo-2-content'>
                  <p className='Career-containerFourTwo-2-Line1'>Recognition</p>
                  <p className='Career-containerFourTwo-2-Line2'>Visible Work Impact</p>
                  <p className='Career-containerFourTwo-2-Line3'>Your work directly contributes to our projects' success and our business growth, unlike other corporations where efforts might get lost.</p>
                </div>
              </div>
            </div>


            {/* 2nd */}
            <div className='Career-containerFourTwo-Two'>
              {/* <div><img src={Mashal} alt="Mashal" /></div> */}
              <div className='Career-containerFourTwo-Two-1'>
                <div className='Career-containerFourTwo-Two-content'>
                  <p className='Career-containerFourTwo-Two-1-Line1'>Leadership</p>
                  <p className='Career-containerFourTwo-Two-1-Line2'>Learning entrepreneurship</p>
                  <p className='Career-containerFourTwo-Two-1-Line3'>Gain insights into business strategy, market dynamics, and startup challenges. Develop skills and mindset with hands-on experience and mentorship.</p>
                </div>
              </div>
              <div className='Career-containerFourTwo-Two-2'>
                {/* <img src={Recognition} alt="Recognition" /> */}
                <div className='Career-containerFourTwo-Two-2-content'>
                  <p className='Career-containerFourTwo-Two-2-Line1'>Innovation</p>
                  <p className='Career-containerFourTwo-Two-2-Line2'>Adopting innovative ways of thinking</p>
                  <p className='Career-containerFourTwo-Two-2-Line3'>Innovation is at our core. Challenge creatively, and explore new ideas and technologies. Shape the future of the tech industry with us.</p>
                </div>
              </div>
            </div>
          </div>

          <div></div>


        </div>


        {/* 5 */}
        <div className='Career-containerFive'>
          <p className='Career-containerFive-Line1'>Explore a world of possibilities and elevate your career</p>
          <p className='Career-containerFive-Line2'> to new heights by &nbsp;<span> <img src={iT} alt="iT" /> </span> &nbsp;  joining our dynamic and innovative team</p>
        </div>

        {/* 6 */}
        <div className='Career-containerSix'>
          <div className='Career-containerSix-one'>
            <p>Ongoing</p>
            <p>Job Postings</p>
          </div>
          {/* content */}
          <div className='Career-containerSix-Two'>
            {
              isTablet
                ?
                <Career />
                :
                <Career2 />
            }
          </div>
          <div>

          </div>
        </div>
      </div>
    </>
  )
}
