export const data = [
    {
      feature: "API Management",
      details: [
        { name: "Versioning", mvp: true, priority: "Nice To Have", develop: {blue:1, nonblue:4, status:"Pending"} },
        { name: "Security (Authentication & Authorization)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        { name: "Rate Limiting", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        { name: "Access Control", mvp: true, priority: "Must Have", develop: {blue:3,nonblue:2,status:"Partially Complete"} },
        { name: "Monitoring and Analytics", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        { name: "Quota Management", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
      ],
    },
    // 2
    {
        feature: "API Lifecycle Management",
        details: [
          { name: "Design, develop, deploy, and retire APIs seamlessly", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Version control to manage multiple versions of APIs", mvp: true, priority: "Nice To Have", develop: {blue:0,nonblue:5,status:"Pending"} },
          { name: "Define and enforce API lifecycle stages (e.g., design, development, testing, staging, production).", mvp: true, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
        ],
      },
    //   3
      {
        feature: "Catalog Management",
        details: [
          { name: "API Catalog", mvp: true, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
          { name: "Version Control", mvp: false, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
          { name: "Lifecycle Management", mvp: false, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
        ],
      },
    //   4
      {
        feature: "Traffic Management",
        details: [
          { name: "Load Balancing", mvp: true, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
          { name: "Routing", mvp: true, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
          { name: "Caching", mvp: true, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
          { name: "Throttling", mvp: true, priority: "Product Map", develop: {blue:5,nonblue:0,status:"Product Map"} },
        ],
      },
    //   5
      {
        feature: "Transformation and Integration",
        details: [
          { name: "Protocol Translation", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Data Transformation", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Request and Response Modification", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   6
      {
        feature: "Logging",
        details: [
          { name: "Request and Response Logging", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Log Aggregation", mvp: false, priority: "Must Have", develop: {blue:0,nonblue:5,status:"Pending"} },
          { name: "Log Analysis", mvp: true, priority: "Nice To Have", develop: {blue:0,nonblue:5,status:"Pending"} },
          { name: "Real-time Monitoring", mvp: true, priority: "Nice To Have", develop: {blue:0,nonblue:5,status:"Pending"} },
          { name: "Log Retention and Archiving", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Security Logging", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   7
      {
        feature: "Security",
        details: [
          { name: "SSL/TLS Termination", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "OAuth/OpenID Connect Support", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "JWT Validation", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "IP Whitelisting/Blacklisting", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "DDoS Protection", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Multi-factor Authentication (MFA)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Custom Authentication Plugins", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Single Sign-On (SSO)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "User Management APIs", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Role-Based Access Control (RBAC)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Attribute-Based Access Control (ABAC)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: " Fine-Grained Access Control", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Policy Enforcement Points (PEPs)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "External Authorization Servers", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Rollback Capabilities", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   8
    {
        feature: "High Availability (HA)",
        details: [
          { name: "Redundant Deployments", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Load Balancing", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Auto-Scaling", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Health Checks", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Session Persistence", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Automated Failover", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   9
      {
        feature: "Disaster Recovery (DR)",
        details: [
          { name: "Replication", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Data Backup and Restore", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Failover Planning", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Geo-Redundancy", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Disaster Recovery Testing", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Automated Failover", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   10
    {
        feature: "Monetization",
        details: [
          { name: "Rate Plans and Pricing Tiers", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Metering and Usage Tracking", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Billing and Invoicing", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Subscription Management", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Monetization Policies", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Developer Revenue Sharing", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "API Analytics and Reporting", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Customizable Branding and Packaging", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "API Marketplace Integration", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Compliance and Security", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },{ name: "Developer Revenue Sharing", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "API Lifecycle Management", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
       ],
      },
    //   11
    {
        feature: "Gateway Compartmentilzation",
        details: [
          { name: "Security Isolation", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Multi-Tenancy Support", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Service Segmentation", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Traffic Management Zones", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Performance Isolation", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Deployment Isolation", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Compliance Segregation", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Fault Isolation", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Scalability Zones", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   12
    {
        feature: "Incident Management",
        details: [
          { name: "Alerting and Notification", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Incident Logging and Tracking", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Incident Triage and Escalation", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Root Cause Analysis", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Incident Resolution and Remediation", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Post-Incident Review and Reporting", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   13
    {
        feature: "Registry Keys Management",
        details: [
          { name: "Secure Storage", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Centralized Management", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Access Control and Permissions", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Rotation and Expiry", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Audit Logging", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Integration with Key Management Systems (KMS)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
    //   14
    {
        feature: "Regulatory & Compliance",
        details: [
          { name: "General Data Protection Regulation (GDPR)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Payment Card Industry Data Security Standard (PCI DSS)", mvp: false, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Health Insurance Portability and Accountability Act (HIPAA)", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Sarbanes-Oxley Act (SOX)", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "European Union Payment Services Directive (PSD2)", mvp: true, priority: "Nice To Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "California Consumer Privacy Act (CCPA)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Federal Information Security Management Act (FISMA)", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
          { name: "Industry-Specific Regulations", mvp: true, priority: "Must Have", develop: {blue:5,nonblue:0,status:"Complete"} },
        ],
      },
  ];