import React, { useState } from 'react';
import '../styles/Carousel.css';

const Carousel = ({slides}) => {
  // console.log(slides)
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <>
    <div className="carousel">
      <div className="carousel-inner" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
        {slides.map((slide, index) => (
          <div className="carousel-item" key={index}>
            <div className='sec6-prt2-cont'>
              {/* <img src={slide.img} alt={slide.title} /> */}
              {slide.img && <img src={slide.img} alt={slide.title} />}
              <h3>{slide.title}</h3>
              <hr />
              <ul>
                {slide.content.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
              <h1 style={{color:"white"}}>{index+1}</h1>
            </div>
            
          </div>
        ))}
      </div>
      <button className="carousel-button prev" onClick={goToPrevious}>❮</button>
      <button className="carousel-button next" onClick={goToNext}>❯</button>
    </div>
    </>
  );
};

export default Carousel;
