import React, { useState } from 'react'
import { Box, Button, Input, Typography } from '@mui/material'
import { faBell } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import notifyBg from '../../assets/Common/notify-bg.png'
import axios from 'axios'

export const Notify = () => {
    const [Email, setEmail] = useState("");
    const [isResponse, setIsResponse] = useState(false)
    const [FormMessage,setFormMessage] = useState(false);

    const url = process.env.REACT_APP_BASE_URL;

    const handlegetnotified = async () => {
        try {
            const response = await axios.post(`${url}/apigateway/form`, { email: Email });

            if (response.status === 200) {
                setIsResponse(true)
                setFormMessage(false)
                setTimeout(() => {
                    setIsResponse(false);
                    setEmail("");
                }, 5000);
            }
        }
        catch (error) {
            setFormMessage(true)
            setIsResponse(false)
            setTimeout(() => {
                setFormMessage(false)
                setEmail("");
            }, 5000);
            console.error('Error:', error);
        }
    }

    return (
        <Box id='notify' sx={{
            color: "white",
            height: {
                xs: 'auto',
                sm: 'auto',
                md: '600px',
                lg: '600px',
                xl: '600px'
            },
            p: {
                xs: 2,
                sm: 2,
                md: 0,
                lg: 0,
                xl: 0
            },
            backgroundImage: `url(${notifyBg})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box sx={{ textAlign: 'center', }}>
                <Typography sx={{
                    fontSize: {
                        xs: '36px',
                        sm: '36px',
                        md: '48px',
                        lg: '48px',
                        xl: '48px',
                    },
                    fontWeight: 'bold',

                }}>Stay Tuned For <br /> The Big Revelation</Typography>
                <Typography sx={{
                    width: {
                        xs: '90%',
                        sm: '90%',
                        md: '60%',
                        lg: '60%',
                        xl: '60%',
                    },
                    fontSize: '18px',
                    m: 'auto',
                    mb: '2rem'
                }}>Be among the first to experience the power of our API management platform.</Typography>

                <Box sx={{
                    p: 1,
                    border: '1.5px solid white',
                    borderRadius: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    width: {
                        xs: '90%',
                        sm: '90%',
                        md: '80%',
                        lg: '80%',
                        xl: '80%',
                    },
                    m: {
                        xs: 'auto',
                        sm: 'auto'
                    }
                }}>
                    <Input disableUnderline placeholder='Email Address' name='email' value={Email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            color: 'white',
                            background: 'transparent',
                            width: {
                                xs: '50%',
                                sm: '60%',
                                md: '70%',
                                lg: '70%',
                                xl: '70%',
                            }
                        }} />
                    <Button
                        onClick={handlegetnotified}
                        endIcon={<FontAwesomeIcon icon={faBell} transform={{ rotate: 45 }} />}
                        sx={{
                            borderRadius: "50px",
                            textTransform: 'none',
                            p: {
                                md: '6px  10px',
                                lg: '6px  10px',
                                xl: '6px  10px',
                            },
                            background: 'white',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: 'white',
                                color: 'black',
                            }
                        }}>Get Notified</Button>
                </Box>
                <Typography sx={{ mt: 2 }}>No spam, only meaningful emails !</Typography>
                {isResponse && <Typography>Thanks for sharing your email! Stay tuned for updates.</Typography>}
                {FormMessage && <Typography>Something went wrong, Please try again later.</Typography>}
            </Box>
        </Box >
    )
}
