// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-4-container {
    width: 70%;
    margin: auto;
    margin-bottom: 10rem;
}

.section-4-container>div p {
    margin: 1rem 0;
}

.section-4-container>div>p {
    font-size: var(--font-size4);
}

.section-4-heading {
    font-size: 3rem;
    margin: 0;

    & p:first-child {
        font-weight: lighter;
    }
}

.bold-heading {
    font-weight: bolder;
}

@media screen and (min-width:300px) and (max-width:840px) {
    .section-4-container {
        width: 80%;
        margin-bottom: var(--margin-bottom-mobile);
    }

    .section-4-heading {
        font-size: 1.1rem;
    }

    .section-4-heading~p {
        font-size: 0.625rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/Homepage/Section4.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,oBAAoB;AACxB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,eAAe;IACf,SAAS;;IAET;QACI,oBAAoB;IACxB;AACJ;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI;QACI,UAAU;QACV,0CAA0C;IAC9C;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".section-4-container {\n    width: 70%;\n    margin: auto;\n    margin-bottom: 10rem;\n}\n\n.section-4-container>div p {\n    margin: 1rem 0;\n}\n\n.section-4-container>div>p {\n    font-size: var(--font-size4);\n}\n\n.section-4-heading {\n    font-size: 3rem;\n    margin: 0;\n\n    & p:first-child {\n        font-weight: lighter;\n    }\n}\n\n.bold-heading {\n    font-weight: bolder;\n}\n\n@media screen and (min-width:300px) and (max-width:840px) {\n    .section-4-container {\n        width: 80%;\n        margin-bottom: var(--margin-bottom-mobile);\n    }\n\n    .section-4-heading {\n        font-size: 1.1rem;\n    }\n\n    .section-4-heading~p {\n        font-size: 0.625rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
