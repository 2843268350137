import React, { useEffect, useState } from 'react'
import blog from "../assets/blog/blog1.png"
import blog2 from "../assets/blog/tabletbgBlog.png"
import "../styles/Blog.css"

export const Blog = () => {
    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        const handleResize = () => {
          setIsTablet(window.innerWidth<1282 && window.innerWidth>500);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
  return (
    <div id='blog-container'>
        <h1>
        case studies <br />
            <span>
            Latest at Unitres Tech
            </span>
        </h1>
        <div id='blog-main-cont'>
            <img src={isTablet?blog2:blog} alt="" />
            <div>
                <button>Launching Soon</button>
                <h3>Stay Tuned for Powerful Insights!</h3>
                <p>Our blog is coming soon, packed with valuable trading strategies and market analysis.</p>

                <div id='blog-button-cont'>
                    <button className='blogButtons'>Market Analysis</button>
                    <button className='blogButtons'>Portfolio Management</button>
                    <button className='blogButtons'>Business Strategy</button>
                    <button className='blogButtons'>NextGen Solutions</button>
                    <button className='blogButtons'>Tech Leadership</button>
                    <button className='blogButtons'>Strategic IT</button>
                </div>
            </div>
        </div>
    </div>
  )
}
